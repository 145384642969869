import React from "react";
import { Link } from "react-router-dom";

function LangChangeLink({ children, lang, status, active, hash }) {
  return (
    <>
      {active && hash ? (
        <Link to={`/${lang}/${status}/${active}${hash}`}>{children}</Link>
      ) : active && !hash ? (
        <Link to={`/${lang}/${status}/${active}`}>{children}</Link>
      ) : (
        <Link to={`/${lang}/${status}`}>{children}</Link>
      )}
    </>
  );
}

export default LangChangeLink;
