import React from "react";
import style from "./Navbar.module.css";

function NavMid({ darkMode, resposive }) {
  return (
    <>
      <div
        className={
          darkMode
            ? `${style.searchContainer} ${style.searchContainerDark}`
            : style.searchContainer
        }
      >
        <img alt="" src="/images/search-dept-icon.svg" />
        <input
          className={darkMode ? `${style.searchInputDark}` : ""}
          type="text"
          placeholder="Search"
          required
        />
      </div>
    </>
  );
}

export default NavMid;
