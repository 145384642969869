import React, { useState } from "react";
import MainBody from "../components/MainBody/MainBody";
import Navbar from "../components/Navbar/Navbar";
import style from "./UserManual.module.css";
import { checkType } from "../functions/checkType";

function UserMaual({
  // desigUrl,
  location,
}) {
  const localDark = checkType(localStorage.getItem("dark"));

  const [darkMode, setDarkMode] = useState(localDark ? localDark : false);
  const [resposive, setResponsive] = useState(window.innerWidth < 680);
  const [openDrawer, setOpenDrawer] = useState(false);

  window.onresize = function () {
    if (window.innerWidth < 680) {
      setResponsive(true);
    } else {
      setResponsive(false);
    }
  };

  return (
    <div className={style.app}>
      <Navbar
        darkMode={darkMode}
        setDarkMode={setDarkMode}
        resposive={resposive}
        setOpenDrawer={setOpenDrawer}
        openDrawer={openDrawer}
        location={location}
      />
      <MainBody
        darkMode={darkMode}
        resposive={resposive}
        setOpenDrawer={setOpenDrawer}
        openDrawer={openDrawer}
        // desigUrl={desigUrl}
      />
    </div>
  );
}

export default UserMaual;
