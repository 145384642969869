import React from "react";
import style from "./Navbar.module.css";

function NavLeft({ darkMode }) {
  return (
    <div className={style.navleft}>
      <a href="https://qviple.com">
        <div className={style.navleftImages}>
          <img
            className={style.qviplelogo}
            src="/images/qviple-logo.svg"
            alt=""
          />
          <img
            className={style.qvipletext}
            src="/images/qviple-text.svg"
            alt=""
          />
        </div>
      </a>

      <h6 className={darkMode ? style.darkh : ""}>Guiding Manual</h6>
    </div>
  );
}

export default NavLeft;
