import React, { useState, Suspense, lazy } from "react";
import style from "../GettingStarted/GettingStarted.module.css";
import { useTranslation } from "react-i18next";
import { Route, Routes } from "react-router-dom";

const GettingStratedUser = lazy(() =>
  import("./GettingStratedUser/GettingStratedUser")
);

const StudentActivityUser = lazy(() =>
  import("./StudentActivityUser/StudentActivityUser")
);
const StaffActivityUser = lazy(() =>
  import("./StaffActivityUser/StaffActivityUser")
);

const DepartmentHead = lazy(() => import("./DepartmentHead/DepartmentHead"));
const ClassTeacher = lazy(() => import("./ClassTeacher/ClassTeacher"));

const FinanceManager = lazy(() => import("./FinanceManager/FinanceManager"));
const SubjectTeacher = lazy(() => import("./SubjectTeacher/SubjectTeacher"));

const AdmissionAdmin = lazy(() => import("./AdmissionAdmin/AdmissionAdmin"));
const Librarian = lazy(() => import("./Librarian/Librarian"));

const EventManager = lazy(() => import("./EventManager/EventManager"));
const GetToKnowDashboardUser = lazy(() =>
  import("./GetToKnowDashboardUser/GetToKnowDashboardUser")
);

const HostelManagement = lazy(() =>
  import("./HostelManagement/HostelManagement")
);

const TransportManager = lazy(() =>
  import("./TransportManager/TransportManager")
);

function UserContent({ darkMode, tabIndex, setTabIndex }) {
  const { t, i18n } = useTranslation();

  const localLang = localStorage.getItem("lang");
  const [isOpen, setIsOpen] = useState({
    regTabs: false,
    eventTabs: false,
  });

  return (
    <div className={style.userContent}>
      <Suspense fallback={<div>Loading...</div>}>
        <Routes>
          <Route
            path={`/:langUrl/user`}
            exact
            element={
              <GettingStratedUser
                darkMode={darkMode}
                tabIndex={tabIndex}
                setTabIndex={setTabIndex}
                isOpen={isOpen}
                setIsOpen={setIsOpen}
              />
            }
          />

          <Route
            path={`/:langUrl/user/dashboard`}
            exact
            element={
              <GetToKnowDashboardUser
                darkMode={darkMode}
                localLang={localLang}
              />
            }
          />

          <Route
            path={`/:langUrl/user/student-activity`}
            exact
            element={<StudentActivityUser darkMode={darkMode} />}
          />

          <Route
            path={`/:langUrl/user/staff-activity`}
            exact
            element={<StaffActivityUser darkMode={darkMode} />}
          />

          <Route
            path={`/:langUrl/user/department-head`}
            exact
            element={
              <DepartmentHead
                darkMode={darkMode}
                tabIndex={tabIndex}
                setTabIndex={setTabIndex}
              />
            }
          />

          <Route
            path={`/:langUrl/user/class-teacher`}
            exact
            element={
              <ClassTeacher
                darkMode={darkMode}
                tabIndex={tabIndex}
                setTabIndex={setTabIndex}
              />
            }
          />

          <Route
            path={`/:langUrl/user/subject-teacher`}
            exact
            element={<SubjectTeacher darkMode={darkMode} />}
          />

          <Route
            path={`/:langUrl/user/finance-manager`}
            exact
            element={
              <FinanceManager
                darkMode={darkMode}
                tabIndex={tabIndex}
                setTabIndex={setTabIndex}
              />
            }
          />

          <Route
            path={`/:langUrl/user/admission-admin`}
            exact
            element={<AdmissionAdmin darkMode={darkMode} />}
          />

          <Route
            path={`/:langUrl/user/librarian`}
            exact
            element={<Librarian darkMode={darkMode} />}
          />

          <Route
            path={`/:langUrl/user/event-manager`}
            exact
            element={<EventManager darkMode={darkMode} />}
          />

          <Route
            path={`/:langUrl/user/hostel-manager`}
            exact
            element={
              <HostelManagement
                darkMode={darkMode}
                tabIndex={tabIndex}
                setTabIndex={setTabIndex}
                isOpen={isOpen}
                setIsOpen={setIsOpen}
              />
            }
          />

          <Route
            path={`/:langUrl/user/transport-manager`}
            exact
            element={<TransportManager darkMode={darkMode} />}
          />

          {/* <h3
        className={
          darkMode && (i18n.language === "hn" || i18n.language === "mt")
            ? `${style.heading3Dark} ${style.heading3Lang}`
            : !darkMode && (i18n.language === "hn" || i18n.language === "mt")
            ? `${style.heading3Light} ${style.heading3Lang}`
            : darkMode && (i18n.language !== "hn" || i18n.language !== "mt")
            ? style.heading3Dark
            : style.heading3Light
        }
      >
        {t("as_sport_head_coach")}
      </h3>
      <div
        className={
          darkMode ? `${style.section} ${style.sectionDark}` : style.section
        }
        id="organise_event"
      >
        <h5
          className={
            darkMode && (i18n.language === "hn" || i18n.language === "mt")
              ? `${style.heading5Dark} ${style.heading5Lang}`
              : !darkMode && (i18n.language === "hn" || i18n.language === "mt")
              ? `${style.heading5Light} ${style.heading5Lang}`
              : darkMode && (i18n.language !== "hn" || i18n.language !== "mt")
              ? style.heading5Dark
              : style.heading5Light
          }
        >
          {t("organise_create_event")}
        </h5>

        <p
          className={darkMode ? style.pDark : style.pLight}
          style={{ lineHeight: "2rem" }}
        >
          <span className={darkMode ? style.stepdark : style.steplight}>
            {t("got_to_member_tab")}
          </span>{" "}
          &nbsp; {"-->"} &nbsp;
          <span className={darkMode ? style.stepdark : style.steplight}>
            {t("select_sport_head_coach")}
          </span>{" "}
          &nbsp; {"-->"} &nbsp;
          <span className={darkMode ? style.stepdark : style.steplight}>
            {t("click_create_new_event")}
          </span>{" "}
          &nbsp; {"-->"} &nbsp;
          <span className={darkMode ? style.stepdark : style.steplight}>
            {t("fill_required_info")}
          </span>{" "}
        </p>
      </div>
      <div
        className={
          darkMode ? `${style.section} ${style.sectionDark}` : style.section
        }
        id="create_matches"
      >
        <h5
          className={
            darkMode && (i18n.language === "hn" || i18n.language === "mt")
              ? `${style.heading5Dark} ${style.heading5Lang}`
              : !darkMode && (i18n.language === "hn" || i18n.language === "mt")
              ? `${style.heading5Light} ${style.heading5Lang}`
              : darkMode && (i18n.language !== "hn" || i18n.language !== "mt")
              ? style.heading5Dark
              : style.heading5Light
          }
        >
          {t("create_matches")}
        </h5>

        <p
          className={darkMode ? style.pDark : style.pLight}
          style={{ lineHeight: "2rem" }}
        >
          <span className={darkMode ? style.stepdark : style.steplight}>
            {t("got_to_member_tab")}
          </span>{" "}
          &nbsp; {"-->"} &nbsp;
          <span className={darkMode ? style.stepdark : style.steplight}>
            {t("select_sport_head_coach")}
          </span>{" "}
          &nbsp; {"-->"} &nbsp;
          <span className={darkMode ? style.stepdark : style.steplight}>
            {t("select_eventyou_want")}
          </span>{" "}
          &nbsp; {"-->"} &nbsp;
          <span className={darkMode ? style.stepdark : style.steplight}>
            {t("click_create_match")}
          </span>{" "}
          &nbsp; {"-->"} &nbsp;
          <span className={darkMode ? style.stepdark : style.steplight}>
            {t("fill_required_info_create_match")}
          </span>{" "}
        </p>
      </div>
      <div
        className={
          darkMode ? `${style.section} ${style.sectionDark}` : style.section
        }
        id="upload_match_result"
      >
        <h5
          className={
            darkMode && (i18n.language === "hn" || i18n.language === "mt")
              ? `${style.heading5Dark} ${style.heading5Lang}`
              : !darkMode && (i18n.language === "hn" || i18n.language === "mt")
              ? `${style.heading5Light} ${style.heading5Lang}`
              : darkMode && (i18n.language !== "hn" || i18n.language !== "mt")
              ? style.heading5Dark
              : style.heading5Light
          }
        >
          {t("upload_match_result")}
        </h5>

        <p
          className={darkMode ? style.pDark : style.pLight}
          style={{ lineHeight: "2rem" }}
        >
          <span className={darkMode ? style.stepdark : style.steplight}>
            {t("got_to_member_tab")}
          </span>{" "}
          &nbsp; {"-->"} &nbsp;
          <span className={darkMode ? style.stepdark : style.steplight}>
            {t("select_sport_head_coach")}
          </span>{" "}
          &nbsp; {"-->"} &nbsp;
          <span className={darkMode ? style.stepdark : style.steplight}>
            {t("select_event")}
          </span>{" "}
          &nbsp; {"-->"} &nbsp;
          <span className={darkMode ? style.stepdark : style.steplight}>
            {t("select_match")}
          </span>{" "}
          <ul style={{ marginTop: "1rem" }}>
            <li className={darkMode ? style.lDark : style.lLight}>
              {t("for_individual_match")} :{" "}
              <span className={darkMode ? style.stepdark : style.steplight}>
                {t("select_winner")}
              </span>{" "}
            </li>
            <li className={darkMode ? style.lDark : style.lLight}>
              {t("team_type_match")}:{" "}
              <span className={darkMode ? style.stepdark : style.steplight}>
                {t("select_winning_team")}
              </span>{" "}
            </li>

            <li className={darkMode ? style.lDark : style.lLight}>
              {t("free_style_type_match")}:{" "}
              <span className={darkMode ? style.stepdark : style.steplight}>
                {t("click_select_winner")}
              </span>{" "}
              &nbsp; {"-->"} &nbsp;
              <span className={darkMode ? style.stepdark : style.steplight}>
                {t("list_of_participants")}
              </span>{" "}
              &nbsp; {"-->"} &nbsp;
              <span className={darkMode ? style.stepdark : style.steplight}>
                {t("click_winner_runner_up")}
              </span>{" "}
            </li>
          </ul>
        </p>
      </div>
      <h3
        className={
          darkMode && (i18n.language === "hn" || i18n.language === "mt")
            ? `${style.heading3Dark} ${style.heading3Lang}`
            : !darkMode && (i18n.language === "hn" || i18n.language === "mt")
            ? `${style.heading3Light} ${style.heading3Lang}`
            : darkMode && (i18n.language !== "hn" || i18n.language !== "mt")
            ? style.heading3Dark
            : style.heading3Light
        }
      >
        {t("sport_class_coach")}
      </h3>
      <div
        className={
          darkMode ? `${style.section} ${style.sectionDark}` : style.section
        }
        id="add_members"
      >
        <h5
          className={
            darkMode && (i18n.language === "hn" || i18n.language === "mt")
              ? `${style.heading5Dark} ${style.heading5Lang}`
              : !darkMode && (i18n.language === "hn" || i18n.language === "mt")
              ? `${style.heading5Light} ${style.heading5Lang}`
              : darkMode && (i18n.language !== "hn" || i18n.language !== "mt")
              ? style.heading5Dark
              : style.heading5Light
          }
        >
          {t("add_members")}
        </h5>

        <p
          className={darkMode ? style.pDark : style.pLight}
          style={{ lineHeight: "2rem" }}
        >
          <span className={darkMode ? style.stepdark : style.steplight}>
            {t("got_to_member_tab")}
          </span>{" "}
          &nbsp; {"-->"} &nbsp;
          <span className={darkMode ? style.stepdark : style.steplight}>
            {t("select_coach")}
          </span>{" "}
          &nbsp; {"-->"} &nbsp;
          <span className={darkMode ? style.stepdark : style.steplight}>
            {t("click_add_member")}
          </span>{" "}
          &nbsp; {"-->"} &nbsp;
          <span className={darkMode ? style.stepdark : style.steplight}>
            {t("search_and_select")}
          </span>{" "}
        </p>
      </div>
      <div
        className={
          darkMode ? `${style.section} ${style.sectionDark}` : style.section
        }
        id="create_team"
      >
        <h5
          className={
            darkMode && (i18n.language === "hn" || i18n.language === "mt")
              ? `${style.heading5Dark} ${style.heading5Lang}`
              : !darkMode && (i18n.language === "hn" || i18n.language === "mt")
              ? `${style.heading5Light} ${style.heading5Lang}`
              : darkMode && (i18n.language !== "hn" || i18n.language !== "mt")
              ? style.heading5Dark
              : style.heading5Light
          }
        >
          {t("create_team")}
        </h5>

        <p
          className={darkMode ? style.pDark : style.pLight}
          style={{ lineHeight: "2rem" }}
        >
          <span className={darkMode ? style.stepdark : style.steplight}>
            {t("got_to_member_tab")}
          </span>{" "}
          &nbsp; {"-->"} &nbsp;
          <span className={darkMode ? style.stepdark : style.steplight}>
            {t("select_coach")}
          </span>{" "}
          &nbsp; {"-->"} &nbsp;
          <span className={darkMode ? style.stepdark : style.steplight}>
            {t("go_teams")}
          </span>{" "}
          &nbsp; {"-->"} &nbsp;
          <span className={darkMode ? style.stepdark : style.steplight}>
            {t("click_create_team")}
          </span>{" "}
          &nbsp; {"-->"} &nbsp;
          <span className={darkMode ? style.stepdark : style.steplight}>
            {t("give_team_name")}
          </span>{" "}
          &nbsp; {"-->"} &nbsp;
          <span className={darkMode ? style.stepdark : style.steplight}>
            {t("select_players")}
          </span>{" "}
          &nbsp; {"-->"} &nbsp;
          <span className={darkMode ? style.stepdark : style.steplight}>
            {t("click_on_save")}
          </span>{" "}
          &nbsp; {"-->"} &nbsp;
          <span className={darkMode ? style.stepdark : style.steplight}>
            {t("add_photo_for_team")}
          </span>{" "}
          &nbsp; {"-->"} &nbsp;
          <span className={darkMode ? style.stepdark : style.steplight}>
            {t("click_on_create")}
          </span>{" "}
        </p>
      </div> */}
        </Routes>
      </Suspense>
    </div>
  );
}

export default UserContent;
