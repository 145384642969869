import React, { useState } from "react";
import style from "./Sidebar.module.css";
import { useTranslation } from "react-i18next";

import { HashLink } from "react-router-hash-link";
import { Link } from "react-router-dom";
import TabChangeLink from "../../../Custom/TabChangeLink";

function Sidebar({
  setStatus,
  status,
  openstatus,
  setOpenStatus,
  darkMode,
  setTabIndex,
}) {
  const { t } = useTranslation();
  const [url, setUrl] = useState("");

  return (
    <div className={style.sidebar}>
      <div className={`row ${style.sidebarHead}`}>
        <div className="col">
          {status === "institute" ? (
            <div className={style.sidebarHeadIcons}>
              <img alt="" src="/images/institute-dark.svg" />
              <p
                className={
                  darkMode ? style.sidebarHeadpdark : style.sidebarHeadpnormal
                }
              >
                {t("ins")}
              </p>
            </div>
          ) : (
            <Link
              to={`/${window.location.pathname.split("/")[1]}/institute
              `}
            >
              <div
                className={style.sidebarHeadIcons}
                onClick={() => {
                  setStatus("institute");

                  localStorage.setItem("desigurl", "institute");
                  setOpenStatus(0);
                }}
              >
                <img alt="" src="/images/institute-icon.svg" />
                <p
                  className={
                    darkMode ? style.sidebarHeadpdark : style.sidebarHeadpnormal
                  }
                >
                  {t("ins")}
                </p>
              </div>
            </Link>
          )}
        </div>
        <div className="col">
          {status === "user" ? (
            <div className={style.sidebarHeadIcons}>
              <img alt="" src="/images/users-white-icon.svg" />
              <p
                className={
                  darkMode ? style.sidebarHeadpdark : style.sidebarHeadpnormal
                }
              >
                {t("user")}
              </p>
            </div>
          ) : (
            <Link to={`/${window.location.pathname.split("/")[1]}/user`}>
              <div
                className={style.sidebarHeadIcons}
                onClick={() => {
                  setStatus("user");

                  localStorage.setItem("desigurl", "user");
                  setOpenStatus(0);
                }}
              >
                <img alt="" src="/images/users-icon.svg" />
                <p
                  className={
                    darkMode ? style.sidebarHeadpdark : style.sidebarHeadpnormal
                  }
                >
                  {t("user")}
                </p>
              </div>
            </Link>
          )}
        </div>
      </div>

      {status === "institute" && (
        <div className={style.sidebarMid}>
          <TabChangeLink status="institute" module={""}>
            <div
              className={
                darkMode && openstatus === 1
                  ? `${style.sections} ${style.sectionsDarkActive}`
                  : !darkMode && openstatus === 1
                  ? `${style.sections} ${style.sectionsActive}`
                  : darkMode && !openstatus === 1
                  ? `${style.sections} ${style.sectionsDarkInactive}`
                  : style.sections
              }
              onClick={() => {
                setOpenStatus(openstatus === 1 ? 0 : 1);
              }}
            >
              {darkMode ? (
                <img alt="" src="/images/login_white_icon.svg" />
              ) : (
                <img alt="" src="/images/getting-started.svg" />
              )}

              <h6
                className={
                  darkMode ? style.primeindexDark : style.primeindexLight
                }
              >
                {t("get_start")}
              </h6>
              {openstatus === 1 ? (
                darkMode ? (
                  <img alt="" src="/images/chevron-up-white.svg" />
                ) : (
                  <img alt="" src="/images/chevron-up.svg" />
                )
              ) : darkMode ? (
                <img alt="" src="/images/chevron-down-white.svg" />
              ) : (
                <img alt="" src="/images/chevron-down.svg" />
              )}
            </div>
          </TabChangeLink>

          {openstatus === 1 && (
            <div
              className={
                darkMode
                  ? style.subIndexContainerDark
                  : style.subIndexContainerLight
              }
            >
              {/* <HashLink smooth style={{ textDecoration: "none" }} to="#setupins">
                <p
                  className={
                    darkMode && url === "setupins"
                      ? style.subIndexDarkActive
                      : !darkMode && url === "setupins"
                      ? style.subIndexLightActive
                      : darkMode && url !== "setupins"
                      ? style.subIndexDark
                      : style.subIndexLight
                  }
                  onClick={() => setUrl("setupins")}
                >
                  {t("how_get_start")}
                </p>
              </HashLink> */}
              <HashLink
                smooth
                style={{ textDecoration: "none" }}
                to="#registerins"
              >
                <p
                  className={
                    darkMode && url === "registerins"
                      ? style.subIndexDarkActive
                      : !darkMode && url === "registerins"
                      ? style.subIndexLightActive
                      : darkMode && url !== "registerins"
                      ? style.subIndexDark
                      : style.subIndexLight
                  }
                  onClick={() => setUrl("registerins")}
                >
                  {t("how_to_register")}
                </p>
              </HashLink>

              <HashLink
                smooth
                style={{ textDecoration: "none" }}
                to="#stepstoregisterins"
              >
                <p
                  className={
                    darkMode && url === "stepstoregisterins"
                      ? style.subIndexDarkActive
                      : !darkMode && url === "stepstoregisterins"
                      ? style.subIndexLightActive
                      : darkMode && url !== "stepstoregisterins"
                      ? style.subIndexDark
                      : style.subIndexLight
                  }
                  onClick={() => setUrl("stepstoregisterins")}
                >
                  {t("step_required_register")}
                </p>
              </HashLink>

              <HashLink
                smooth
                style={{ textDecoration: "none" }}
                to="#setupprofile"
              >
                <p
                  className={
                    darkMode && url === "setupprofile"
                      ? style.subIndexDarkActive
                      : !darkMode && url === "setupprofile"
                      ? style.subIndexLightActive
                      : darkMode && url !== "setupprofile"
                      ? style.subIndexDark
                      : style.subIndexLight
                  }
                  onClick={() => setUrl("setupprofile")}
                >
                  {t("setup_profile")}
                </p>
              </HashLink>
            </div>
          )}

          <TabChangeLink status="institute" module="dashboard">
            <div
              className={
                darkMode && openstatus === 2
                  ? `${style.sections} ${style.sectionsDarkActive}`
                  : !darkMode && openstatus === 2
                  ? `${style.sections} ${style.sectionsActive}`
                  : darkMode && !openstatus === 2
                  ? `${style.sections} ${style.sectionsDarkInactive}`
                  : style.sections
              }
              onClick={() => {
                setOpenStatus(openstatus === 2 ? 0 : 2);
              }}
            >
              {darkMode ? (
                <img alt="" src="/images/department-icon-white.png" />
              ) : (
                <img alt="" src="/images/department-icon.svg" />
              )}

              <h6
                className={
                  darkMode ? style.primeindexDark : style.primeindexLight
                }
              >
                {t("get_know")}
              </h6>
              {openstatus === 2 ? (
                darkMode ? (
                  <img alt="" src="/images/chevron-up-white.svg" />
                ) : (
                  <img alt="" src="/images/chevron-up.svg" />
                )
              ) : darkMode ? (
                <img alt="" src="/images/chevron-down-white.svg" />
              ) : (
                <img alt="" src="/images/chevron-down.svg" />
              )}
            </div>
          </TabChangeLink>

          {openstatus === 2 && (
            <div
              className={
                darkMode
                  ? style.subIndexContainerDark
                  : style.subIndexContainerLight
              }
            >
              <HashLink smooth style={{ textDecoration: "none" }} to="#desig">
                <p
                  className={
                    darkMode && url === "desig"
                      ? style.subIndexDarkActive
                      : !darkMode && url === "desig"
                      ? style.subIndexLightActive
                      : darkMode && url !== "desig"
                      ? style.subIndexDark
                      : style.subIndexLight
                  }
                  onClick={() => setUrl("desig")}
                >
                  {t("designation_menu")}
                </p>
              </HashLink>
              <HashLink
                smooth
                style={{ textDecoration: "none" }}
                to="#posttypes"
              >
                <p
                  className={
                    darkMode && url === "posttypes"
                      ? style.subIndexDarkActive
                      : !darkMode && url === "posttypes"
                      ? style.subIndexLightActive
                      : darkMode && url !== "posttypes"
                      ? style.subIndexDark
                      : style.subIndexLight
                  }
                  onClick={() => setUrl("posttypes")}
                >
                  {t("feeds")}
                </p>
              </HashLink>
              <HashLink smooth style={{ textDecoration: "none" }} to="#tabs">
                <p
                  className={
                    darkMode && url === "tabs"
                      ? style.subIndexDarkActive
                      : !darkMode && url === "tabs"
                      ? style.subIndexLightActive
                      : darkMode && url !== "tabs"
                      ? style.subIndexDark
                      : style.subIndexLight
                  }
                  onClick={() => setUrl("tabs")}
                >
                  {t("activated_modules")}
                </p>
              </HashLink>
            </div>
          )}

          <TabChangeLink status="institute" module="setup">
            <div
              className={
                darkMode && openstatus === 3
                  ? `${style.sections} ${style.sectionsDarkActive}`
                  : !darkMode && openstatus === 3
                  ? `${style.sections} ${style.sectionsActive}`
                  : darkMode && !openstatus === 3
                  ? `${style.sections} ${style.sectionsDarkInactive}`
                  : style.sections
              }
              onClick={() => {
                setOpenStatus(openstatus === 3 ? 0 : 3);
              }}
            >
              {darkMode ? (
                <img alt="" src="/images/setUpWhite_icon.svg" />
              ) : (
                <img alt="" src="/images/setUp_icon.svg" />
              )}
              <h6
                className={
                  darkMode ? style.primeindexDark : style.primeindexLight
                }
              >
                {t("initial_setup")}
              </h6>
              {openstatus === 3 ? (
                darkMode ? (
                  <img alt="" src="/images/chevron-up-white.svg" />
                ) : (
                  <img alt="" src="/images/chevron-up.svg" />
                )
              ) : darkMode ? (
                <img alt="" src="/images/chevron-down-white.svg" />
              ) : (
                <img alt="" src="/images/chevron-down.svg" />
              )}
            </div>
          </TabChangeLink>

          {openstatus === 3 && (
            <div
              className={
                darkMode
                  ? style.subIndexContainerDark
                  : style.subIndexContainerLight
              }
            >
              {/* <HashLink
                smooth
                style={{ textDecoration: "none" }}
                to="#setjoinstaff"
              >
                <p
                  className={
                    darkMode && url === "setjoinstaff"
                      ? style.subIndexDarkActive
                      : !darkMode && url === "setjoinstaff"
                      ? style.subIndexLightActive
                      : darkMode && url !== "setjoinstaff"
                      ? style.subIndexDark
                      : style.subIndexLight
                  }
                  onClick={() => setUrl("setjoinstaff")}
                >
                  {t("step_join_staff")}
                </p>
              </HashLink> */}
              <HashLink
                smooth
                style={{ textDecoration: "none" }}
                to="#setnewdepartment"
              >
                <p
                  className={
                    darkMode && url === "setnewdepartment"
                      ? style.subIndexDarkActive
                      : !darkMode && url === "setnewdepartment"
                      ? style.subIndexLightActive
                      : darkMode && url !== "setnewdepartment"
                      ? style.subIndexDark
                      : style.subIndexLight
                  }
                  onClick={() => setUrl("setnewdepartment")}
                >
                  {t("set_new_department")}
                </p>
              </HashLink>

              <HashLink
                smooth
                style={{ textDecoration: "none" }}
                to="#setnewbatch"
              >
                <p
                  className={
                    darkMode && url === "setnewbatch"
                      ? style.subIndexDarkActive
                      : !darkMode && url === "setnewbatch"
                      ? style.subIndexLightActive
                      : darkMode && url !== "setnewbatch"
                      ? style.subIndexDark
                      : style.subIndexLight
                  }
                  onClick={() => setUrl("setnewbatch")}
                >
                  {t("set_new_batch")}
                </p>
              </HashLink>

              <HashLink
                smooth
                style={{ textDecoration: "none" }}
                to="#setnewclassmaster"
              >
                <p
                  className={
                    darkMode && url === "setnewclassmaster"
                      ? style.subIndexDarkActive
                      : !darkMode && url === "setnewclassmaster"
                      ? style.subIndexLightActive
                      : darkMode && url !== "setnewclassmaster"
                      ? style.subIndexDark
                      : style.subIndexLight
                  }
                  onClick={() => setUrl("setnewclassmaster")}
                >
                  {t("set_new_class_master")}
                </p>
              </HashLink>

              <HashLink
                smooth
                style={{ textDecoration: "none" }}
                to="#setnewclass"
              >
                <p
                  className={
                    darkMode && url === "setnewclass"
                      ? style.subIndexDarkActive
                      : !darkMode && url === "setnewclass"
                      ? style.subIndexLightActive
                      : darkMode && url !== "setnewclass"
                      ? style.subIndexDark
                      : style.subIndexLight
                  }
                  onClick={() => setUrl("setnewclass")}
                >
                  {t("set_new_class")}
                </p>
              </HashLink>

              {/* <HashLink
                smooth
                style={{ textDecoration: "none" }}
                to="#setnewsubjectmaster"
              >
                <p
                  className={
                    darkMode && url === "setnewsubjectmaster"
                      ? style.subIndexDarkActive
                      : !darkMode && url === "setnewsubjectmaster"
                      ? style.subIndexLightActive
                      : darkMode && url !== "setnewsubjectmaster"
                      ? style.subIndexDark
                      : style.subIndexLight
                  }
                  onClick={() => setUrl("setnewsubjectmaster")}
                >
                  {t("set_new_subject_master")}
                </p>
              </HashLink> */}

              <HashLink
                smooth
                style={{ textDecoration: "none" }}
                to="#setnewsubject"
              >
                <p
                  className={
                    darkMode && url === "setnewsubject"
                      ? style.subIndexDarkActive
                      : !darkMode && url === "setnewsubject"
                      ? style.subIndexLightActive
                      : darkMode && url !== "setnewsubject"
                      ? style.subIndexDark
                      : style.subIndexLight
                  }
                  onClick={() => setUrl("setnewsubject")}
                >
                  {t("set_new_subject")}
                </p>
              </HashLink>

              <HashLink
                smooth
                style={{ textDecoration: "none" }}
                to="#setnewtimetable"
              >
                <p
                  className={
                    darkMode && url === "setnewtimetable"
                      ? style.subIndexDarkActive
                      : !darkMode && url === "setnewtimetable"
                      ? style.subIndexLightActive
                      : darkMode && url !== "setnewtimetable"
                      ? style.subIndexDark
                      : style.subIndexLight
                  }
                  onClick={() => setUrl("setnewtimetable")}
                >
                  {t("set_new_time_table")}
                </p>
              </HashLink>

              {/* <HashLink
                smooth
                style={{ textDecoration: "none" }}
                to="#setnewfinance"
              >
                <p
                  className={
                    darkMode && url === "setnewfinance"
                      ? style.subIndexDarkActive
                      : !darkMode && url === "setnewfinance"
                      ? style.subIndexLightActive
                      : darkMode && url !== "setnewfinance"
                      ? style.subIndexDark
                      : style.subIndexLight
                  }
                  onClick={() => setUrl("setnewfinance")}
                >
                  {t("set_new_finance")}
                </p>
              </HashLink>

              <HashLink
                smooth
                style={{ textDecoration: "none" }}
                to="#setnewadmission"
              >
                <p
                  className={
                    darkMode && url === "setnewadmission"
                      ? style.subIndexDarkActive
                      : !darkMode && url === "setnewadmission"
                      ? style.subIndexLightActive
                      : darkMode && url !== "setnewadmission"
                      ? style.subIndexDark
                      : style.subIndexLight
                  }
                  onClick={() => setUrl("setnewadmission")}
                >
                  {t("set_new_admission")}
                </p>
              </HashLink> */}

              <HashLink
                smooth
                style={{ textDecoration: "none" }}
                to="#bystudentcode"
              >
                <p
                  className={
                    darkMode && url === "bystudentcode"
                      ? style.subIndexDarkActive
                      : !darkMode && url === "bystudentcode"
                      ? style.subIndexLightActive
                      : darkMode && url !== "bystudentcode"
                      ? style.subIndexDark
                      : style.subIndexLight
                  }
                  onClick={() => setUrl("bystudentcode")}
                >
                  {t("by_student_code")}
                </p>
              </HashLink>
            </div>
          )}

          <TabChangeLink status="institute" module="staff-activity">
            <div
              className={
                darkMode && openstatus === 4
                  ? `${style.sections} ${style.sectionsDarkActive}`
                  : !darkMode && openstatus === 4
                  ? `${style.sections} ${style.sectionsActive}`
                  : darkMode && !openstatus === 4
                  ? `${style.sections} ${style.sectionsDarkInactive}`
                  : style.sections
              }
              onClick={() => {
                setOpenStatus(openstatus === 4 ? 0 : 4);
              }}
            >
              {darkMode ? (
                <img alt="" src="/images/user-white.svg" />
              ) : (
                <img alt="" src="/images/user.svg" />
              )}
              <h6
                className={
                  darkMode ? style.primeindexDark : style.primeindexLight
                }
              >
                {t("staff_activities")}
              </h6>
              {openstatus === 4 ? (
                darkMode ? (
                  <img alt="" src="/images/chevron-up-white.svg" />
                ) : (
                  <img alt="" src="/images/chevron-up.svg" />
                )
              ) : darkMode ? (
                <img alt="" src="/images/chevron-down-white.svg" />
              ) : (
                <img alt="" src="/images/chevron-down.svg" />
              )}
            </div>
          </TabChangeLink>

          {openstatus === 4 && (
            <div
              className={
                darkMode
                  ? style.subIndexContainerDark
                  : style.subIndexContainerLight
              }
            >
              <HashLink
                smooth
                style={{ textDecoration: "none" }}
                to="#staff_activities"
              >
                <p
                  className={
                    darkMode && url === "staff_activities"
                      ? style.subIndexDarkActive
                      : !darkMode && url === "staff_activities"
                      ? style.subIndexLightActive
                      : darkMode && url !== "staff_activities"
                      ? style.subIndexDark
                      : style.subIndexLight
                  }
                  onClick={() => setUrl("staff_activities")}
                >
                  {t("see_staff")}
                </p>
              </HashLink>

              <HashLink
                smooth
                style={{ textDecoration: "none" }}
                to="#view_staff_request"
              >
                <p
                  className={
                    darkMode && url === "view_staff_request"
                      ? style.subIndexDarkActive
                      : !darkMode && url === "view_staff_request"
                      ? style.subIndexLightActive
                      : darkMode && url !== "view_staff_request"
                      ? style.subIndexDark
                      : style.subIndexLight
                  }
                  onClick={() => setUrl("view_staff_request")}
                >
                  {t("view_staff_request")}
                </p>
              </HashLink>

              <HashLink
                smooth
                style={{ textDecoration: "none" }}
                to="#mark_attendance_staff"
              >
                <p
                  className={
                    darkMode && url === "mark_attendance_staff"
                      ? style.subIndexDarkActive
                      : !darkMode && url === "mark_attendance_staff"
                      ? style.subIndexLightActive
                      : darkMode && url !== "mark_attendance_staff"
                      ? style.subIndexDark
                      : style.subIndexLight
                  }
                  onClick={() => setUrl("mark_attendance_staff")}
                >
                  {t("mark_attendance_staff")}
                </p>
              </HashLink>
              <HashLink
                smooth
                style={{ textDecoration: "none" }}
                to="#leaves_transfer_request"
              >
                <p
                  className={
                    darkMode && url === "leaves_transfer_request"
                      ? style.subIndexDarkActive
                      : !darkMode && url === "leaves_transfer_request"
                      ? style.subIndexLightActive
                      : darkMode && url !== "leaves_transfer_request"
                      ? style.subIndexDark
                      : style.subIndexLight
                  }
                  onClick={() => setUrl("leaves_transfer_request")}
                >
                  {t("leaves_transfer_request")}
                </p>
              </HashLink>
              <HashLink
                smooth
                style={{ textDecoration: "none" }}
                to="#staff_complaints"
              >
                <p
                  className={
                    darkMode && url === "staff_complaints"
                      ? style.subIndexDarkActive
                      : !darkMode && url === "staff_complaints"
                      ? style.subIndexLightActive
                      : darkMode && url !== "staff_complaints"
                      ? style.subIndexDark
                      : style.subIndexLight
                  }
                  onClick={() => setUrl("staff_complaints")}
                >
                  {t("staff_complaints")}
                </p>
              </HashLink>
            </div>
          )}

          <TabChangeLink status="institute" module="student-activity">
            <div
              className={
                darkMode && openstatus === 5
                  ? `${style.sections} ${style.sectionsDarkActive}`
                  : !darkMode && openstatus === 5
                  ? `${style.sections} ${style.sectionsActive}`
                  : darkMode && !openstatus === 5
                  ? `${style.sections} ${style.sectionsDarkInactive}`
                  : style.sections
              }
              onClick={() => {
                setOpenStatus(openstatus === 5 ? 0 : 5);
              }}
            >
              {darkMode ? (
                <img alt="" src="/images/student-white.png" />
              ) : (
                <img alt="" src="/images/student.png" />
              )}
              <h6
                className={
                  darkMode ? style.primeindexDark : style.primeindexLight
                }
              >
                {t("student_activities")}
              </h6>
              {openstatus === 5 ? (
                darkMode ? (
                  <img alt="" src="/images/chevron-up-white.svg" />
                ) : (
                  <img alt="" src="/images/chevron-up.svg" />
                )
              ) : darkMode ? (
                <img alt="" src="/images/chevron-down-white.svg" />
              ) : (
                <img alt="" src="/images/chevron-down.svg" />
              )}
            </div>
          </TabChangeLink>

          {openstatus === 5 && (
            <div
              className={
                darkMode
                  ? style.subIndexContainerDark
                  : style.subIndexContainerLight
              }
            >
              <HashLink
                smooth
                style={{ textDecoration: "none" }}
                to="#student_activities"
              >
                <p
                  className={
                    darkMode && url === "student_activities"
                      ? style.subIndexDarkActive
                      : !darkMode && url === "student_activities"
                      ? style.subIndexLightActive
                      : darkMode && url !== "student_activities"
                      ? style.subIndexDark
                      : style.subIndexLight
                  }
                  onClick={() => setUrl("student_activities")}
                >
                  {t("see_student")}
                </p>
              </HashLink>

              <HashLink
                smooth
                style={{ textDecoration: "none" }}
                to="#view_student_detail"
              >
                <p
                  className={
                    darkMode && url === "view_student_detail"
                      ? style.subIndexDarkActive
                      : !darkMode && url === "view_student_detail"
                      ? style.subIndexLightActive
                      : darkMode && url !== "view_student_detail"
                      ? style.subIndexDark
                      : style.subIndexLight
                  }
                  onClick={() => setUrl("view_student_detail")}
                >
                  {t("view_student_detail")}
                </p>
              </HashLink>
              <HashLink
                smooth
                style={{ textDecoration: "none" }}
                to="#student_settings_"
              >
                <p
                  className={
                    darkMode && url === "student_settings_"
                      ? style.subIndexDarkActive
                      : !darkMode && url === "student_settings_"
                      ? style.subIndexLightActive
                      : darkMode && url !== "student_settings_"
                      ? style.subIndexDark
                      : style.subIndexLight
                  }
                  onClick={() => setUrl("student_settings_")}
                >
                  {t("student_settings_")}
                </p>
              </HashLink>
              <HashLink
                smooth
                style={{ textDecoration: "none" }}
                to="#student_certificates"
              >
                <p
                  className={
                    darkMode && url === "student_certificates"
                      ? style.subIndexDarkActive
                      : !darkMode && url === "student_certificates"
                      ? style.subIndexLightActive
                      : darkMode && url !== "student_certificates"
                      ? style.subIndexDark
                      : style.subIndexLight
                  }
                  onClick={() => setUrl("student_certificates")}
                >
                  {t("student_certificates")}
                </p>
              </HashLink>
              <HashLink smooth style={{ textDecoration: "none" }} to="#id_card">
                <p
                  className={
                    darkMode && url === "id_card"
                      ? style.subIndexDarkActive
                      : !darkMode && url === "id_card"
                      ? style.subIndexLightActive
                      : darkMode && url !== "id_card"
                      ? style.subIndexDark
                      : style.subIndexLight
                  }
                  onClick={() => setUrl("id_card")}
                >
                  {t("id_card")}
                </p>
              </HashLink>

              <HashLink
                smooth
                style={{ textDecoration: "none" }}
                to="#complaints"
              >
                <p
                  className={
                    darkMode && url === "complaints"
                      ? style.subIndexDarkActive
                      : !darkMode && url === "complaints"
                      ? style.subIndexLightActive
                      : darkMode && url !== "complaints"
                      ? style.subIndexDark
                      : style.subIndexLight
                  }
                  onClick={() => setUrl("complaints")}
                >
                  {t("complaints")}
                </p>
              </HashLink>
            </div>
          )}
        </div>
      )}

      {status === "user" && (
        <div className={style.sidebarMid}>
          <TabChangeLink status="user" module="">
            <div
              className={
                darkMode && openstatus === 1
                  ? `${style.sections} ${style.sectionsDarkActive}`
                  : !darkMode && openstatus === 1
                  ? `${style.sections} ${style.sectionsActive}`
                  : darkMode && !openstatus === 1
                  ? `${style.sections} ${style.sectionsDarkInactive}`
                  : style.sections
              }
              onClick={() => {
                setOpenStatus(openstatus === 1 ? 0 : 1);
              }}
            >
              {darkMode ? (
                <img alt="" src="/images/getting-started-white.svg" />
              ) : (
                <img alt="" src="/images/getting-started.svg" />
              )}

              <h6
                className={
                  darkMode ? style.primeindexDark : style.primeindexLight
                }
              >
                {t("get_start")}
              </h6>
              {openstatus === 1 ? (
                darkMode ? (
                  <img alt="" src="/images/chevron-up-white.svg" />
                ) : (
                  <img alt="" src="/images/chevron-up.svg" />
                )
              ) : darkMode ? (
                <img alt="" src="/images/chevron-down-white.svg" />
              ) : (
                <img alt="" src="/images/chevron-down.svg" />
              )}
            </div>
          </TabChangeLink>

          {openstatus === 1 && (
            <div
              className={
                darkMode
                  ? style.subIndexContainerDark
                  : style.subIndexContainerLight
              }
            >
              <HashLink
                smooth
                style={{ textDecoration: "none" }}
                to="#user_signup"
              >
                <p
                  className={
                    darkMode && url === "user_signup"
                      ? style.subIndexDarkActive
                      : !darkMode && url === "user_signup"
                      ? style.subIndexLightActive
                      : darkMode && url !== "user_signup"
                      ? style.subIndexDark
                      : style.subIndexLight
                  }
                  onClick={() => setUrl("user_signup")}
                >
                  {t("how_to_sign_up")}
                </p>
              </HashLink>

              <HashLink
                smooth
                style={{ textDecoration: "none" }}
                to="#user_signup_steps"
              >
                <p
                  className={
                    darkMode && url === "user_signup_steps"
                      ? style.subIndexDarkActive
                      : !darkMode && url === "user_signup_steps"
                      ? style.subIndexLightActive
                      : darkMode && url !== "user_signup_steps"
                      ? style.subIndexDark
                      : style.subIndexLight
                  }
                  onClick={() => setUrl("user_signup_steps")}
                >
                  {t("create_account_steps")}
                </p>
              </HashLink>

              <HashLink
                smooth
                style={{ textDecoration: "none" }}
                to="#setup_profile"
              >
                <p
                  className={
                    darkMode && url === "setup_profile"
                      ? style.subIndexDarkActive
                      : !darkMode && url === "setup_profile"
                      ? style.subIndexLightActive
                      : darkMode && url !== "setup_profile"
                      ? style.subIndexDark
                      : style.subIndexLight
                  }
                  onClick={() => setUrl("setup_profile")}
                >
                  {t("how_to_set_profile")}
                </p>
              </HashLink>
            </div>
          )}

          <TabChangeLink status="user" module="dashboard">
            <div
              className={
                darkMode && openstatus === 2
                  ? `${style.sections} ${style.sectionsDarkActive}`
                  : !darkMode && openstatus === 2
                  ? `${style.sections} ${style.sectionsActive}`
                  : darkMode && !openstatus === 2
                  ? `${style.sections} ${style.sectionsDarkInactive}`
                  : style.sections
              }
              onClick={() => {
                setOpenStatus(openstatus === 2 ? 0 : 2);
              }}
            >
              {darkMode ? (
                <img alt="" src="/images/department-icon-white.png" />
              ) : (
                <img alt="" src="/images/department-icon.png" />
              )}

              <h6
                className={
                  darkMode ? style.primeindexDark : style.primeindexLight
                }
              >
                {t("get_know")}
              </h6>
              {openstatus === 2 ? (
                darkMode ? (
                  <img alt="" src="/images/chevron-up-white.svg" />
                ) : (
                  <img alt="" src="/images/chevron-up.svg" />
                )
              ) : darkMode ? (
                <img alt="" src="/images/chevron-down-white.svg" />
              ) : (
                <img alt="" src="/images/chevron-down.svg" />
              )}
            </div>
          </TabChangeLink>

          {openstatus === 2 && (
            <div
              className={
                darkMode
                  ? style.subIndexContainerDark
                  : style.subIndexContainerLight
              }
            >
              <HashLink
                smooth
                style={{ textDecoration: "none" }}
                to="#user_tabs"
              >
                <p
                  className={
                    darkMode && url === "user_tabs"
                      ? style.subIndexDarkActive
                      : !darkMode && url === "user_tabs"
                      ? style.subIndexLightActive
                      : darkMode && url !== "user_tabs"
                      ? style.subIndexDark
                      : style.subIndexLight
                  }
                  onClick={() => setUrl("user_tabs")}
                >
                  {t("profile_counts")}
                </p>
              </HashLink>

              <HashLink
                smooth
                style={{ textDecoration: "none" }}
                to="#posttypes"
              >
                <p
                  className={
                    darkMode && url === "posttypes"
                      ? style.subIndexDarkActive
                      : !darkMode && url === "posttypes"
                      ? style.subIndexLightActive
                      : darkMode && url !== "posttypes"
                      ? style.subIndexDark
                      : style.subIndexLight
                  }
                  onClick={() => setUrl("posttypes")}
                >
                  {t("feeds")}
                </p>
              </HashLink>

              <HashLink
                smooth
                style={{ textDecoration: "none" }}
                to="#trending"
              >
                <p
                  className={
                    darkMode && url === "trending"
                      ? style.subIndexDarkActive
                      : !darkMode && url === "trending"
                      ? style.subIndexLightActive
                      : darkMode && url !== "trending"
                      ? style.subIndexDark
                      : style.subIndexLight
                  }
                  onClick={() => setUrl("trending")}
                >
                  {t("trending_section")}
                </p>
              </HashLink>
            </div>
          )}

          <TabChangeLink status="user" module="student-activity">
            <div
              className={
                darkMode && openstatus === 3
                  ? `${style.sections} ${style.sectionsDarkActive}`
                  : !darkMode && openstatus === 3
                  ? `${style.sections} ${style.sectionsActive}`
                  : darkMode && !openstatus === 3
                  ? `${style.sections} ${style.sectionsDarkInactive}`
                  : style.sections
              }
              onClick={() => {
                setOpenStatus(openstatus === 3 ? 0 : 3);
              }}
            >
              {darkMode ? (
                <img alt="" src="/images/student-white.png" />
              ) : (
                <img alt="" src="/images/student.png" />
              )}
              <h6
                className={
                  darkMode ? style.primeindexDark : style.primeindexLight
                }
              >
                {t("student_activities")}
              </h6>
              {openstatus === 3 ? (
                darkMode ? (
                  <img alt="" src="/images/chevron-up-white.svg" />
                ) : (
                  <img alt="" src="/images/chevron-up.svg" />
                )
              ) : darkMode ? (
                <img alt="" src="/images/chevron-down-white.svg" />
              ) : (
                <img alt="" src="/images/chevron-down.svg" />
              )}
            </div>
          </TabChangeLink>

          {openstatus === 3 && (
            <div
              className={
                darkMode
                  ? style.subIndexContainerDark
                  : style.subIndexContainerLight
              }
            >
              <HashLink
                smooth
                style={{ textDecoration: "none" }}
                to="#join_as_student"
              >
                <p
                  className={
                    darkMode && url === "join_as_student"
                      ? style.subIndexDarkActive
                      : !darkMode && url === "join_as_student"
                      ? style.subIndexLightActive
                      : darkMode && url !== "join_as_student"
                      ? style.subIndexDark
                      : style.subIndexLight
                  }
                  onClick={() => setUrl("join_as_student")}
                >
                  {t("join_as_student")}
                </p>
              </HashLink>
              <HashLink
                smooth
                style={{ textDecoration: "none" }}
                to="#access_as_student"
              >
                <p
                  className={
                    darkMode && url === "access_as_student"
                      ? style.subIndexDarkActive
                      : !darkMode && url === "access_as_student"
                      ? style.subIndexLightActive
                      : darkMode && url !== "access_as_student"
                      ? style.subIndexDark
                      : style.subIndexLight
                  }
                  onClick={() => setUrl("access_as_student")}
                >
                  {t("access_student_data")}
                </p>
              </HashLink>
              <HashLink
                smooth
                style={{ textDecoration: "none" }}
                to="#student_make_complaints"
              >
                <p
                  className={
                    darkMode && url === "student_make_complaints"
                      ? style.subIndexDarkActive
                      : !darkMode && url === "student_make_complaints"
                      ? style.subIndexLightActive
                      : darkMode && url !== "student_make_complaints"
                      ? style.subIndexDark
                      : style.subIndexLight
                  }
                  onClick={() => setUrl("student_make_complaints")}
                >
                  {t("make_complaints")}
                </p>
              </HashLink>

              <HashLink
                smooth
                style={{ textDecoration: "none" }}
                to="#student_leave_request"
              >
                <p
                  className={
                    darkMode && url === "student_leave_request"
                      ? style.subIndexDarkActive
                      : !darkMode && url === "student_leave_request"
                      ? style.subIndexLightActive
                      : darkMode && url !== "student_leave_request"
                      ? style.subIndexDark
                      : style.subIndexLight
                  }
                  onClick={() => setUrl("student_leave_request")}
                >
                  {t("how_to_apply_leave_transfer")}
                </p>
              </HashLink>

              {/* <HashLink
                smooth
                style={{ textDecoration: "none" }}
                to="#student_transfer_request"
              >
                <p
                  className={
                    darkMode && url === "student_transfer_request"
                      ? style.subIndexDarkActive
                      : !darkMode && url === "student_transfer_request"
                      ? style.subIndexLightActive
                      : darkMode && url !== "student_transfer_request"
                      ? style.subIndexDark
                      : style.subIndexLight
                  }
                  onClick={() => setUrl("student_transfer_request")}
                >
                  {t("request_for_transfer")}
                </p>
              </HashLink> */}

              <HashLink
                smooth
                style={{ textDecoration: "none" }}
                to="#student_switch_account"
              >
                <p
                  className={
                    darkMode && url === "student_switch_account"
                      ? style.subIndexDarkActive
                      : !darkMode && url === "student_switch_account"
                      ? style.subIndexLightActive
                      : darkMode && url !== "student_switch_account"
                      ? style.subIndexDark
                      : style.subIndexLight
                  }
                  onClick={() => setUrl("student_switch_account")}
                >
                  {t("switch_multiple_ins")}
                </p>
              </HashLink>

              <HashLink
                smooth
                style={{ textDecoration: "none" }}
                to="#student_timetable"
              >
                <p
                  className={
                    darkMode && url === "student_timetable"
                      ? style.subIndexDarkActive
                      : !darkMode && url === "student_timetable"
                      ? style.subIndexLightActive
                      : darkMode && url !== "student_timetable"
                      ? style.subIndexDark
                      : style.subIndexLight
                  }
                  onClick={() => setUrl("student_timetable")}
                >
                  {t("ho_to_check_attendance")}
                </p>
              </HashLink>

              {/* <HashLink
                smooth
                style={{ textDecoration: "none" }}
                to="#student_attendence"
              >
                <p
                  className={
                    darkMode && url === "student_attendence"
                      ? style.subIndexDarkActive
                      : !darkMode && url === "student_attendence"
                      ? style.subIndexLightActive
                      : darkMode && url !== "student_attendence"
                      ? style.subIndexDark
                      : style.subIndexLight
                  }
                  onClick={() => setUrl("student_attendence")}
                >
                  {t("check_attendence")}
                </p>
              </HashLink> */}

              <HashLink
                smooth
                style={{ textDecoration: "none" }}
                to="#student_daily_updates"
              >
                <p
                  className={
                    darkMode && url === "student_daily_updates"
                      ? style.subIndexDarkActive
                      : !darkMode && url === "student_daily_updates"
                      ? style.subIndexLightActive
                      : darkMode && url !== "student_daily_updates"
                      ? style.subIndexDark
                      : style.subIndexLight
                  }
                  onClick={() => setUrl("student_daily_updates")}
                >
                  {t("daily_updates_check")}
                </p>
              </HashLink>

              <HashLink
                smooth
                style={{ textDecoration: "none" }}
                to="#student_assignments"
              >
                <p
                  className={
                    darkMode && url === "student_assignments"
                      ? style.subIndexDarkActive
                      : !darkMode && url === "student_assignments"
                      ? style.subIndexLightActive
                      : darkMode && url !== "student_assignments"
                      ? style.subIndexDark
                      : style.subIndexLight
                  }
                  onClick={() => setUrl("student_assignments")}
                >
                  {t("check_assignment")}
                </p>
              </HashLink>

              <HashLink
                smooth
                style={{ textDecoration: "none" }}
                to="#student_fees"
              >
                <p
                  className={
                    darkMode && url === "student_fees"
                      ? style.subIndexDarkActive
                      : !darkMode && url === "student_fees"
                      ? style.subIndexLightActive
                      : darkMode && url !== "student_fees"
                      ? style.subIndexDark
                      : style.subIndexLight
                  }
                  onClick={() => setUrl("student_fees")}
                >
                  {t("pay_fees")}
                </p>
              </HashLink>

              <HashLink
                smooth
                style={{ textDecoration: "none" }}
                to="#student_examinations"
              >
                <p
                  className={
                    darkMode && url === "student_examinations"
                      ? style.subIndexDarkActive
                      : !darkMode && url === "student_examinations"
                      ? style.subIndexLightActive
                      : darkMode && url !== "student_examinations"
                      ? style.subIndexDark
                      : style.subIndexLight
                  }
                  onClick={() => setUrl("student_examinations")}
                >
                  {t("check_exam")}
                </p>
              </HashLink>
            </div>
          )}

          <TabChangeLink status="user" module="staff-activity">
            <div
              className={
                darkMode && openstatus === 4
                  ? `${style.sections} ${style.sectionsDarkActive}`
                  : !darkMode && openstatus === 4
                  ? `${style.sections} ${style.sectionsActive}`
                  : darkMode && !openstatus === 4
                  ? `${style.sections} ${style.sectionsDarkInactive}`
                  : style.sections
              }
              onClick={() => {
                setOpenStatus(openstatus === 4 ? 0 : 4);
              }}
            >
              {darkMode ? (
                <img alt="" src="/images/user-white.svg" />
              ) : (
                <img alt="" src="/images/user.svg" />
              )}
              <h6
                className={
                  darkMode ? style.primeindexDark : style.primeindexLight
                }
              >
                {t("staff_activities")}
              </h6>
              {openstatus === 4 ? (
                darkMode ? (
                  <img alt="" src="/images/chevron-up-white.svg" />
                ) : (
                  <img alt="" src="/images/chevron-up.svg" />
                )
              ) : darkMode ? (
                <img alt="" src="/images/chevron-down-white.svg" />
              ) : (
                <img alt="" src="/images/chevron-down.svg" />
              )}
            </div>
          </TabChangeLink>

          {openstatus === 4 && (
            <div
              className={
                darkMode
                  ? style.subIndexContainerDark
                  : style.subIndexContainerLight
              }
            >
              <HashLink
                smooth
                style={{ textDecoration: "none" }}
                to="#join_as_staff"
              >
                <p
                  className={
                    darkMode && url === "join_as_staff"
                      ? style.subIndexDarkActive
                      : !darkMode && url === "join_as_staff"
                      ? style.subIndexLightActive
                      : darkMode && url !== "join_as_staff"
                      ? style.subIndexDark
                      : style.subIndexLight
                  }
                  onClick={() => setUrl("join_as_staff")}
                >
                  {t("join_as_staff")}
                </p>
              </HashLink>
              <HashLink
                smooth
                style={{ textDecoration: "none" }}
                to="#access_as_staff"
              >
                <p
                  className={
                    darkMode && url === "access_as_staff"
                      ? style.subIndexDarkActive
                      : !darkMode && url === "access_as_staff"
                      ? style.subIndexLightActive
                      : darkMode && url !== "access_as_staff"
                      ? style.subIndexDark
                      : style.subIndexLight
                  }
                  onClick={() => setUrl("access_as_staff")}
                >
                  {t("access_staff_data")}
                </p>
              </HashLink>
              <HashLink
                smooth
                style={{ textDecoration: "none" }}
                to="#staff_make_complaints"
              >
                <p
                  className={
                    darkMode && url === "staff_make_complaints"
                      ? style.subIndexDarkActive
                      : !darkMode && url === "staff_make_complaints"
                      ? style.subIndexLightActive
                      : darkMode && url !== "staff_make_complaints"
                      ? style.subIndexDark
                      : style.subIndexLight
                  }
                  onClick={() => setUrl("staff_make_complaints")}
                >
                  {t("make_complaints")}
                </p>
              </HashLink>

              <HashLink
                smooth
                style={{ textDecoration: "none" }}
                to="#staff_leave_request"
              >
                <p
                  className={
                    darkMode && url === "staff_leave_request"
                      ? style.subIndexDarkActive
                      : !darkMode && url === "staff_leave_request"
                      ? style.subIndexLightActive
                      : darkMode && url !== "staff_leave_request"
                      ? style.subIndexDark
                      : style.subIndexLight
                  }
                  onClick={() => setUrl("staff_leave_request")}
                >
                  {t("ask_for_leaves")}
                </p>
              </HashLink>

              <HashLink
                smooth
                style={{ textDecoration: "none" }}
                to="#staff_transfer_request"
              >
                <p
                  className={
                    darkMode && url === "staff_transfer_request"
                      ? style.subIndexDarkActive
                      : !darkMode && url === "staff_transfer_request"
                      ? style.subIndexLightActive
                      : darkMode && url !== "staff_transfer_request"
                      ? style.subIndexDark
                      : style.subIndexLight
                  }
                  onClick={() => setUrl("staff_transfer_request")}
                >
                  {t("request_for_transfer")}
                </p>
              </HashLink>

              <HashLink
                smooth
                style={{ textDecoration: "none" }}
                to="#staff_switch_account"
              >
                <p
                  className={
                    darkMode && url === "staff_switch_account"
                      ? style.subIndexDarkActive
                      : !darkMode && url === "staff_switch_account"
                      ? style.subIndexLightActive
                      : darkMode && url !== "staff_switch_account"
                      ? style.subIndexDark
                      : style.subIndexLight
                  }
                  onClick={() => setUrl("staff_switch_account")}
                >
                  {t("switch_ins")}
                </p>
              </HashLink>
            </div>
          )}

          <TabChangeLink status="user" module="department-head">
            <div
              className={
                darkMode && openstatus === 5
                  ? `${style.sections} ${style.sectionsDarkActive}`
                  : !darkMode && openstatus === 5
                  ? `${style.sections} ${style.sectionsActive}`
                  : darkMode && !openstatus === 5
                  ? `${style.sections} ${style.sectionsDarkInactive}`
                  : style.sections
              }
              onClick={() => {
                setOpenStatus(openstatus === 5 ? 0 : 5);
              }}
            >
              {darkMode ? (
                <img alt="" src="/images/department-white.png" />
              ) : (
                <img alt="" src="/images/department.png" />
              )}
              <h6
                className={
                  darkMode ? style.primeindexDark : style.primeindexLight
                }
              >
                {t("work_as_dhead")}
              </h6>
              {openstatus === 5 ? (
                darkMode ? (
                  <img alt="" src="/images/chevron-up-white.svg" />
                ) : (
                  <img alt="" src="/images/chevron-up.svg" />
                )
              ) : darkMode ? (
                <img alt="" src="/images/chevron-down-white.svg" />
              ) : (
                <img alt="" src="/images/chevron-down.svg" />
              )}
            </div>
          </TabChangeLink>

          {openstatus === 5 && (
            <div
              className={
                darkMode
                  ? style.subIndexContainerDark
                  : style.subIndexContainerLight
              }
            >
              <HashLink
                smooth
                style={{ textDecoration: "none" }}
                to="#view_stafflist"
              >
                <p
                  className={
                    darkMode && url === "view_stafflist"
                      ? style.subIndexDarkActive
                      : !darkMode && url === "view_stafflist"
                      ? style.subIndexLightActive
                      : darkMode && url !== "view_stafflist"
                      ? style.subIndexDark
                      : style.subIndexLight
                  }
                  onClick={() => setUrl("view_stafflist")}
                >
                  {t("view_stafflist")}
                </p>
              </HashLink>

              <HashLink
                smooth
                style={{ textDecoration: "none" }}
                to="#createexam"
              >
                <p
                  className={
                    darkMode && url === "createexam"
                      ? style.subIndexDarkActive
                      : !darkMode && url === "createexam"
                      ? style.subIndexLightActive
                      : darkMode && url !== "createexam"
                      ? style.subIndexDark
                      : style.subIndexLight
                  }
                  onClick={() => setUrl("createexam")}
                >
                  {t("create_exam")}
                </p>
              </HashLink>
              <HashLink
                smooth
                style={{ textDecoration: "none" }}
                to="#createfees"
              >
                <p
                  className={
                    darkMode && url === "createfees"
                      ? style.subIndexDarkActive
                      : !darkMode && url === "createfees"
                      ? style.subIndexLightActive
                      : darkMode && url !== "createfees"
                      ? style.subIndexDark
                      : style.subIndexLight
                  }
                  onClick={() => setUrl("createfees")}
                >
                  {t("create_fees")}
                </p>
              </HashLink>
              <HashLink
                smooth
                style={{ textDecoration: "none" }}
                to="#createParticipativeEvent"
              >
                <p
                  className={
                    darkMode && url === "createParticipativeEvent"
                      ? style.subIndexDarkActive
                      : !darkMode && url === "createParticipativeEvent"
                      ? style.subIndexLightActive
                      : darkMode && url !== "createParticipativeEvent"
                      ? style.subIndexDark
                      : style.subIndexLight
                  }
                  onClick={() => setUrl("createParticipativeEvent")}
                >
                  {t("create_election")}
                </p>
              </HashLink>
              <HashLink
                smooth
                style={{ textDecoration: "none" }}
                to="#createholiday"
              >
                <p
                  className={
                    darkMode && url === "createholiday"
                      ? style.subIndexDarkActive
                      : !darkMode && url === "createholiday"
                      ? style.subIndexLightActive
                      : darkMode && url !== "createholiday"
                      ? style.subIndexDark
                      : style.subIndexLight
                  }
                  onClick={() => setUrl("createholiday")}
                >
                  {t("create_holiday")}
                </p>
              </HashLink>

              {/* <HashLink
                smooth
                style={{ textDecoration: "none" }}
                to="#linkbiometric"
              >
                <p
                  className={
                    darkMode && url === "linkbiometric"
                      ? style.subIndexDarkActive
                      : !darkMode && url === "linkbiometric"
                      ? style.subIndexLightActive
                      : darkMode && url !== "linkbiometric"
                      ? style.subIndexDark
                      : style.subIndexLight
                  }
                  onClick={() => setUrl("linkbiometric")}
                >
                  {t("link_bio_metrix")}
                </p>
              </HashLink> */}
            </div>
          )}

          <TabChangeLink status="user" module="class-teacher">
            <div
              className={
                darkMode && openstatus === 6
                  ? `${style.sections} ${style.sectionsDarkActive}`
                  : !darkMode && openstatus === 6
                  ? `${style.sections} ${style.sectionsActive}`
                  : darkMode && !openstatus === 6
                  ? `${style.sections} ${style.sectionsDarkInactive}`
                  : style.sections
              }
              onClick={() => {
                setOpenStatus(openstatus === 6 ? 0 : 6);
              }}
            >
              {darkMode ? (
                <img alt="" src="/images/class-teacher-white.svg" />
              ) : (
                <img alt="" src="/images/class-teacher.svg" />
              )}
              <h6
                className={
                  darkMode ? style.primeindexDark : style.primeindexLight
                }
              >
                {t("as_class_teacher")}
              </h6>
              {openstatus === 6 ? (
                darkMode ? (
                  <img alt="" src="/images/chevron-up-white.svg" />
                ) : (
                  <img alt="" src="/images/chevron-up.svg" />
                )
              ) : darkMode ? (
                <img alt="" src="/images/chevron-down-white.svg" />
              ) : (
                <img alt="" src="/images/chevron-down.svg" />
              )}
            </div>
          </TabChangeLink>

          {openstatus === 6 && (
            <div
              className={
                darkMode
                  ? style.subIndexContainerDark
                  : style.subIndexContainerLight
              }
            >
              <HashLink
                smooth
                style={{ textDecoration: "none" }}
                to="#classCatalog"
              >
                <p
                  className={
                    darkMode && url === "classCatalog"
                      ? style.subIndexDarkActive
                      : !darkMode && url === "classCatalog"
                      ? style.subIndexLightActive
                      : darkMode && url !== "classCatalog"
                      ? style.subIndexDark
                      : style.subIndexLight
                  }
                  onClick={() => setUrl("classCatalog")}
                >
                  {t("class_catalog")}
                </p>
              </HashLink>
              <HashLink
                smooth
                style={{ textDecoration: "none" }}
                to="#studentcode"
              >
                <p
                  className={
                    darkMode && url === "studentcode"
                      ? style.subIndexDarkActive
                      : !darkMode && url === "studentcode"
                      ? style.subIndexLightActive
                      : darkMode && url !== "studentcode"
                      ? style.subIndexDark
                      : style.subIndexLight
                  }
                  onClick={() => setUrl("studentcode")}
                >
                  {t("st_join_code")}
                </p>
              </HashLink>
              <HashLink
                smooth
                style={{ textDecoration: "none" }}
                to="#studentrequest"
              >
                <p
                  className={
                    darkMode && url === "studentrequest"
                      ? style.subIndexDarkActive
                      : !darkMode && url === "studentrequest"
                      ? style.subIndexLightActive
                      : darkMode && url !== "studentrequest"
                      ? style.subIndexDark
                      : style.subIndexLight
                  }
                  onClick={() => setUrl("studentrequest")}
                >
                  {t("accept_student_joining")}
                </p>
              </HashLink>

              <HashLink
                smooth
                style={{ textDecoration: "none" }}
                to="#teacher_attendence"
              >
                <p
                  className={
                    darkMode && url === "teacher_attendence"
                      ? style.subIndexDarkActive
                      : !darkMode && url === "teacher_attendence"
                      ? style.subIndexLightActive
                      : darkMode && url !== "teacher_attendence"
                      ? style.subIndexDark
                      : style.subIndexLight
                  }
                  onClick={() => setUrl("teacher_attendence")}
                >
                  {t("attendence_manual")}
                </p>
              </HashLink>

              <HashLink
                smooth
                style={{ textDecoration: "none" }}
                to="#link_student_attendence"
              >
                <p
                  className={
                    darkMode && url === "link_student_attendence"
                      ? style.subIndexDarkActive
                      : !darkMode && url === "link_student_attendence"
                      ? style.subIndexLightActive
                      : darkMode && url !== "link_student_attendence"
                      ? style.subIndexDark
                      : style.subIndexLight
                  }
                  onClick={() => setUrl("link_student_attendence")}
                >
                  {t("link_student_attendence")}
                </p>
              </HashLink>

              <HashLink
                smooth
                style={{ textDecoration: "none" }}
                to="#offlinefees"
              >
                <p
                  className={
                    darkMode && url === "offlinefees"
                      ? style.subIndexDarkActive
                      : !darkMode && url === "offlinefees"
                      ? style.subIndexLightActive
                      : darkMode && url !== "offlinefees"
                      ? style.subIndexDark
                      : style.subIndexLight
                  }
                  onClick={() => setUrl("offlinefees")}
                >
                  {t("collect_fees_offline")}
                </p>
              </HashLink>

              <HashLink
                smooth
                style={{ textDecoration: "none" }}
                to="#finalreport"
              >
                <p
                  className={
                    darkMode && url === "finalreport"
                      ? style.subIndexDarkActive
                      : !darkMode && url === "finalreport"
                      ? style.subIndexLightActive
                      : darkMode && url !== "finalreport"
                      ? style.subIndexDark
                      : style.subIndexLight
                  }
                  onClick={() => setUrl("finalreport")}
                >
                  {t("prepare_final_report")}
                </p>
              </HashLink>

              <HashLink
                smooth
                style={{ textDecoration: "none" }}
                to="#solvecomplaints"
              >
                <p
                  className={
                    darkMode && url === "solvecomplaints"
                      ? style.subIndexDarkActive
                      : !darkMode && url === "solvecomplaints"
                      ? style.subIndexLightActive
                      : darkMode && url !== "solvecomplaints"
                      ? style.subIndexDark
                      : style.subIndexLight
                  }
                  onClick={() => setUrl("solvecomplaints")}
                >
                  {t("view_solve_complaints")}
                </p>
              </HashLink>

              <HashLink
                smooth
                style={{ textDecoration: "none" }}
                to="#transferrequests"
              >
                <p
                  className={
                    darkMode && url === "transferrequests"
                      ? style.subIndexDarkActive
                      : !darkMode && url === "transferrequests"
                      ? style.subIndexLightActive
                      : darkMode && url !== "transferrequests"
                      ? style.subIndexDark
                      : style.subIndexLight
                  }
                  onClick={() => setUrl("transferrequests")}
                >
                  {t("check_leaves")}
                </p>
              </HashLink>

              <HashLink
                smooth
                style={{ textDecoration: "none" }}
                to="#updateBehaviour"
              >
                <p
                  className={
                    darkMode && url === "updateBehaviour"
                      ? style.subIndexDarkActive
                      : !darkMode && url === "updateBehaviour"
                      ? style.subIndexLightActive
                      : darkMode && url !== "updateBehaviour"
                      ? style.subIndexDark
                      : style.subIndexLight
                  }
                  onClick={() => setUrl("updateBehaviour")}
                >
                  {t("update_behaviour")}
                </p>
              </HashLink>

              <HashLink
                smooth
                style={{ textDecoration: "none" }}
                to="#promotestudents"
              >
                <p
                  className={
                    darkMode && url === "promotestudents"
                      ? style.subIndexDarkActive
                      : !darkMode && url === "promotestudents"
                      ? style.subIndexLightActive
                      : darkMode && url !== "promotestudents"
                      ? style.subIndexDark
                      : style.subIndexLight
                  }
                  onClick={() => setUrl("promotestudents")}
                >
                  {t("promote_student_next")}
                </p>
              </HashLink>
              <HashLink
                smooth
                style={{ textDecoration: "none" }}
                to="#completeclass"
              >
                <p
                  className={
                    darkMode && url === "completeclass"
                      ? style.subIndexDarkActive
                      : !darkMode && url === "completeclass"
                      ? style.subIndexLightActive
                      : darkMode && url !== "completeclass"
                      ? style.subIndexDark
                      : style.subIndexLight
                  }
                  onClick={() => setUrl("completeclass")}
                >
                  {t("complete_classs")}
                </p>
              </HashLink>
            </div>
          )}

          <TabChangeLink status="user" module="subject-teacher">
            <div
              className={
                darkMode && openstatus === 7
                  ? `${style.sections} ${style.sectionsDarkActive}`
                  : !darkMode && openstatus === 7
                  ? `${style.sections} ${style.sectionsActive}`
                  : darkMode && !openstatus === 7
                  ? `${style.sections} ${style.sectionsDarkInactive}`
                  : style.sections
              }
              onClick={() => {
                setOpenStatus(openstatus === 7 ? 0 : 7);
              }}
            >
              {darkMode ? (
                <img alt="" src="/images/subject-teacher-white.svg" />
              ) : (
                <img alt="" src="/images/subject-teacher.svg" />
              )}
              <h6
                className={
                  darkMode ? style.primeindexDark : style.primeindexLight
                }
              >
                {t("as_subject_teacher")}
              </h6>
              {openstatus === 7 ? (
                darkMode ? (
                  <img alt="" src="/images/chevron-up-white.svg" />
                ) : (
                  <img alt="" src="/images/chevron-up.svg" />
                )
              ) : darkMode ? (
                <img alt="" src="/images/chevron-down-white.svg" />
              ) : (
                <img alt="" src="/images/chevron-down.svg" />
              )}
            </div>
          </TabChangeLink>

          {openstatus === 7 && (
            <div
              className={
                darkMode
                  ? style.subIndexContainerDark
                  : style.subIndexContainerLight
              }
            >
              <HashLink
                smooth
                style={{ textDecoration: "none" }}
                to="#subjectviewcatalog"
              >
                <p
                  className={
                    darkMode && url === "subjectviewcatalog"
                      ? style.subIndexDarkActive
                      : !darkMode && url === "subjectviewcatalog"
                      ? style.subIndexLightActive
                      : darkMode && url !== "subjectviewcatalog"
                      ? style.subIndexDark
                      : style.subIndexLight
                  }
                  onClick={() => setUrl("subjectviewcatalog")}
                >
                  {t("view_catalog")}
                </p>
              </HashLink>
              <HashLink
                smooth
                style={{ textDecoration: "none" }}
                to="#mocktest"
              >
                <p
                  className={
                    darkMode && url === "mocktest"
                      ? style.subIndexDarkActive
                      : !darkMode && url === "mocktest"
                      ? style.subIndexLightActive
                      : darkMode && url !== "mocktest"
                      ? style.subIndexDark
                      : style.subIndexLight
                  }
                  onClick={() => setUrl("mocktest")}
                >
                  {t("take_mcq")}
                </p>
              </HashLink>
              <HashLink
                smooth
                style={{ textDecoration: "none" }}
                to="#createmcq"
              >
                <p
                  className={
                    darkMode && url === "createmcq"
                      ? style.subIndexDarkActive
                      : !darkMode && url === "createmcq"
                      ? style.subIndexLightActive
                      : darkMode && url !== "createmcq"
                      ? style.subIndexDark
                      : style.subIndexLight
                  }
                  onClick={() => setUrl("createmcq")}
                >
                  {t("create_mcq")}
                </p>
              </HashLink>

              <HashLink
                smooth
                style={{ textDecoration: "none" }}
                to="#uploadmarks"
              >
                <p
                  className={
                    darkMode && url === "uploadmarks"
                      ? style.subIndexDarkActive
                      : !darkMode && url === "uploadmarks"
                      ? style.subIndexLightActive
                      : darkMode && url !== "uploadmarks"
                      ? style.subIndexDark
                      : style.subIndexLight
                  }
                  onClick={() => setUrl("uploadmarks")}
                >
                  {t("upload_marks")}
                </p>
              </HashLink>

              <HashLink
                smooth
                style={{ textDecoration: "none" }}
                to="#createassignment"
              >
                <p
                  className={
                    darkMode && url === "createassignment"
                      ? style.subIndexDarkActive
                      : !darkMode && url === "createassignment"
                      ? style.subIndexLightActive
                      : darkMode && url !== "createassignment"
                      ? style.subIndexDark
                      : style.subIndexLight
                  }
                  onClick={() => setUrl("createassignment")}
                >
                  {t("how_create_assignment")}
                </p>
              </HashLink>

              {/* <HashLink smooth style={{ textDecoration: "none" }} to="#updatediary">
                <p
                  className={
                    darkMode && url === "updatediary"
                      ? style.subIndexDarkActive
                      : !darkMode && url === "updatediary"
                      ? style.subIndexLightActive
                      : darkMode && url !== "updatediary"
                      ? style.subIndexDark
                      : style.subIndexLight
                  }
                  onClick={() => setUrl("updatediary")}
                >
                  {t("update_daily_diary")}
                </p>
              </HashLink> */}

              {/* <HashLink
                smooth
                style={{ textDecoration: "none" }}
                to="#passingmarks"
              >
                <p
                  className={
                    darkMode && url === "passingmarks"
                      ? style.subIndexDarkActive
                      : !darkMode && url === "passingmarks"
                      ? style.subIndexLightActive
                      : darkMode && url !== "passingmarks"
                      ? style.subIndexDark
                      : style.subIndexLight
                  }
                  onClick={() => setUrl("passingmarks")}
                >
                  {t("how_to_set_passing_marks")}
                </p>
              </HashLink> */}

              <HashLink
                smooth
                style={{ textDecoration: "none" }}
                to="#completesubject"
              >
                <p
                  className={
                    darkMode && url === "completesubject"
                      ? style.subIndexDarkActive
                      : !darkMode && url === "completesubject"
                      ? style.subIndexLightActive
                      : darkMode && url !== "completesubject"
                      ? style.subIndexDark
                      : style.subIndexLight
                  }
                  onClick={() => setUrl("completesubject")}
                >
                  {t("how_to_set_complete_subject")}
                </p>
              </HashLink>
            </div>
          )}

          <TabChangeLink status="user" module="finance-manager">
            <div
              className={
                darkMode && openstatus === 8
                  ? `${style.sections} ${style.sectionsDarkActive}`
                  : !darkMode && openstatus === 8
                  ? `${style.sections} ${style.sectionsActive}`
                  : darkMode && !openstatus === 8
                  ? `${style.sections} ${style.sectionsDarkInactive}`
                  : style.sections
              }
              onClick={() => {
                setOpenStatus(openstatus === 8 ? 0 : 8);
              }}
            >
              {darkMode ? (
                <img alt="" src="/images/finance_white.svg" />
              ) : (
                <img alt="" src="/images/finance.svg" />
              )}
              <h6
                className={
                  darkMode ? style.primeindexDark : style.primeindexLight
                }
              >
                {t("as_finance_manager")}
              </h6>
              {openstatus === 8 ? (
                darkMode ? (
                  <img alt="" src="/images/chevron-up-white.svg" />
                ) : (
                  <img alt="" src="/images/chevron-up.svg" />
                )
              ) : darkMode ? (
                <img alt="" src="/images/chevron-down-white.svg" />
              ) : (
                <img alt="" src="/images/chevron-down.svg" />
              )}
            </div>
          </TabChangeLink>

          {openstatus === 8 && (
            <div
              className={
                darkMode
                  ? style.subIndexContainerDark
                  : style.subIndexContainerLight
              }
            >
              <HashLink
                smooth
                style={{ textDecoration: "none" }}
                to="#checkinscode"
                onClick={() => setTabIndex(0)}
              >
                <p
                  className={
                    darkMode && url === "checkinscode"
                      ? style.subIndexDarkActive
                      : !darkMode && url === "checkinscode"
                      ? style.subIndexLightActive
                      : darkMode && url !== "checkinscode"
                      ? style.subIndexDark
                      : style.subIndexLight
                  }
                  onClick={() => setUrl("checkinscode")}
                >
                  {t("check_ins_code")}
                </p>
              </HashLink>
              <HashLink
                smooth
                style={{ textDecoration: "none" }}
                to="#incomes"
                onClick={() => setTabIndex(0)}
              >
                <p
                  className={
                    darkMode && url === "incomes"
                      ? style.subIndexDarkActive
                      : !darkMode && url === "incomes"
                      ? style.subIndexLightActive
                      : darkMode && url !== "incomes"
                      ? style.subIndexDark
                      : style.subIndexLight
                  }
                  onClick={() => setUrl("incomes")}
                >
                  {t("account_incomes")}
                </p>
              </HashLink>
              <HashLink
                smooth
                style={{ textDecoration: "none" }}
                to="#expenses"
                onClick={() => setTabIndex(0)}
              >
                <p
                  className={
                    darkMode && url === "expenses"
                      ? style.subIndexDarkActive
                      : !darkMode && url === "expenses"
                      ? style.subIndexLightActive
                      : darkMode && url !== "expenses"
                      ? style.subIndexDark
                      : style.subIndexLight
                  }
                  onClick={() => setUrl("expenses")}
                >
                  {t("account_expense")}
                </p>
              </HashLink>

              <HashLink
                smooth
                style={{ textDecoration: "none" }}
                to="#addemployees"
                onClick={() => setTabIndex(0)}
              >
                <p
                  className={
                    darkMode && url === "addemployees"
                      ? style.subIndexDarkActive
                      : !darkMode && url === "addemployees"
                      ? style.subIndexLightActive
                      : darkMode && url !== "addemployees"
                      ? style.subIndexDark
                      : style.subIndexLight
                  }
                  onClick={() => setUrl("addemployees")}
                >
                  {t("add_employees")}
                </p>
              </HashLink>

              <HashLink
                smooth
                style={{ textDecoration: "none" }}
                to="#collectofflinefees"
                onClick={() => setTabIndex(0)}
              >
                <p
                  className={
                    darkMode && url === "collectofflinefees"
                      ? style.subIndexDarkActive
                      : !darkMode && url === "collectofflinefees"
                      ? style.subIndexLightActive
                      : darkMode && url !== "collectofflinefees"
                      ? style.subIndexDark
                      : style.subIndexLight
                  }
                  onClick={() => setUrl("collectofflinefees")}
                >
                  {t("collect_offline_fees")}
                </p>
              </HashLink>

              <HashLink
                smooth
                style={{ textDecoration: "none" }}
                to="#pendingfees"
                onClick={() => setTabIndex(0)}
              >
                <p
                  className={
                    darkMode && url === "pendingfees"
                      ? style.subIndexDarkActive
                      : !darkMode && url === "pendingfees"
                      ? style.subIndexLightActive
                      : darkMode && url !== "pendingfees"
                      ? style.subIndexDark
                      : style.subIndexLight
                  }
                  onClick={() => setUrl("pendingfees")}
                >
                  {t("check_pending_fees")}
                </p>
              </HashLink>

              <HashLink
                smooth
                style={{ textDecoration: "none" }}
                to="#check_repayment"
                onClick={() => setTabIndex(0)}
              >
                <p
                  className={
                    darkMode && url === "check_repayment"
                      ? style.subIndexDarkActive
                      : !darkMode && url === "check_repayment"
                      ? style.subIndexLightActive
                      : darkMode && url !== "check_repayment"
                      ? style.subIndexDark
                      : style.subIndexLight
                  }
                  onClick={() => setUrl("check_repayment")}
                >
                  {t("check_payment")}
                </p>
              </HashLink>

              <HashLink
                smooth
                style={{ textDecoration: "none" }}
                to="#transaction_history"
                onClick={() => setTabIndex(0)}
              >
                <p
                  className={
                    darkMode && url === "transaction_history"
                      ? style.subIndexDarkActive
                      : !darkMode && url === "transaction_history"
                      ? style.subIndexLightActive
                      : darkMode && url !== "transaction_history"
                      ? style.subIndexDark
                      : style.subIndexLight
                  }
                  onClick={() => setUrl("transaction_history")}
                >
                  {t("see_transaction_history")}
                </p>
              </HashLink>

              <HashLink
                smooth
                style={{ textDecoration: "none" }}
                to="#bankAccount"
                onClick={() => setTabIndex(0)}
              >
                <p
                  className={
                    darkMode && url === "bankAccount"
                      ? style.subIndexDarkActive
                      : !darkMode && url === "bankAccount"
                      ? style.subIndexLightActive
                      : darkMode && url !== "bankAccount"
                      ? style.subIndexDark
                      : style.subIndexLight
                  }
                  onClick={() => setUrl("bankAccount")}
                >
                  {t("how_to_check_bank_account")}
                </p>
              </HashLink>

              <HashLink
                smooth
                style={{ textDecoration: "none" }}
                to="#data_export"
                onClick={() => setTabIndex(0)}
              >
                <p
                  className={
                    darkMode && url === "data_export"
                      ? style.subIndexDarkActive
                      : !darkMode && url === "data_export"
                      ? style.subIndexLightActive
                      : darkMode && url !== "data_export"
                      ? style.subIndexDark
                      : style.subIndexLight
                  }
                  onClick={() => setUrl("data_export")}
                >
                  {t("data_export_heading")}
                </p>
              </HashLink>

              <HashLink
                smooth
                style={{ textDecoration: "none" }}
                to="#deposit"
                onClick={() => setTabIndex(0)}
              >
                <p
                  className={
                    darkMode && url === "deposit"
                      ? style.subIndexDarkActive
                      : !darkMode && url === "deposit"
                      ? style.subIndexLightActive
                      : darkMode && url !== "deposit"
                      ? style.subIndexDark
                      : style.subIndexLight
                  }
                  onClick={() => setUrl("deposit")}
                >
                  {t("deposit")}
                </p>
              </HashLink>

              <HashLink
                smooth
                style={{ textDecoration: "none" }}
                to="#financeModerator"
                onClick={() => setTabIndex(0)}
              >
                <p
                  className={
                    darkMode && url === "financeModerator"
                      ? style.subIndexDarkActive
                      : !darkMode && url === "financeModerator"
                      ? style.subIndexLightActive
                      : darkMode && url !== "financeModerator"
                      ? style.subIndexDark
                      : style.subIndexLight
                  }
                  onClick={() => setUrl("financeModerator")}
                >
                  {t("finance_moderator")}
                </p>
              </HashLink>

              <HashLink
                smooth
                style={{ textDecoration: "none" }}
                to="#feeStructure"
                onClick={() => setTabIndex(1)}
              >
                <p
                  className={
                    darkMode && url === "feeStructure"
                      ? style.subIndexDarkActive
                      : !darkMode && url === "feeStructure"
                      ? style.subIndexLightActive
                      : darkMode && url !== "feeStructure"
                      ? style.subIndexDark
                      : style.subIndexLight
                  }
                  onClick={() => setUrl("feeStructure")}
                >
                  {t("fee_structure")}
                </p>
              </HashLink>

              <HashLink
                smooth
                style={{ textDecoration: "none" }}
                to="#insFund"
                onClick={() => setTabIndex(2)}
              >
                <p
                  className={
                    darkMode && url === "insFund"
                      ? style.subIndexDarkActive
                      : !darkMode && url === "insFund"
                      ? style.subIndexLightActive
                      : darkMode && url !== "insFund"
                      ? style.subIndexDark
                      : style.subIndexLight
                  }
                  onClick={() => setUrl("insFund")}
                >
                  {t("fund_heading")}
                </p>
              </HashLink>
            </div>
          )}

          <TabChangeLink status="user" module="admission-admin">
            <div
              className={
                darkMode && openstatus === 9
                  ? `${style.sections} ${style.sectionsDarkActive}`
                  : !darkMode && openstatus === 9
                  ? `${style.sections} ${style.sectionsActive}`
                  : darkMode && !openstatus === 9
                  ? `${style.sections} ${style.sectionsDarkInactive}`
                  : style.sections
              }
              onClick={() => {
                setOpenStatus(openstatus === 9 ? 0 : 9);
              }}
            >
              {darkMode ? (
                <img alt="" src="/images/admisionIconWhite.svg" />
              ) : (
                <img alt="" src="/images/admisionIcon.svg" />
              )}
              <h6
                className={
                  darkMode ? style.primeindexDark : style.primeindexLight
                }
              >
                {t("as_admission_admin")}
              </h6>
              {openstatus === 9 ? (
                darkMode ? (
                  <img alt="" src="/images/chevron-up-white.svg" />
                ) : (
                  <img alt="" src="/images/chevron-up.svg" />
                )
              ) : darkMode ? (
                <img alt="" src="/images/chevron-down-white.svg" />
              ) : (
                <img alt="" src="/images/chevron-down.svg" />
              )}
            </div>
          </TabChangeLink>

          {openstatus === 9 && (
            <div
              className={
                darkMode
                  ? style.subIndexContainerDark
                  : style.subIndexContainerLight
              }
            >
              <HashLink
                smooth
                style={{ textDecoration: "none" }}
                to="#new_application"
              >
                <p
                  className={
                    darkMode && url === "new_application"
                      ? style.subIndexDarkActive
                      : !darkMode && url === "new_application"
                      ? style.subIndexLightActive
                      : darkMode && url !== "new_application"
                      ? style.subIndexDark
                      : style.subIndexLight
                  }
                  onClick={() => setUrl("new_application")}
                >
                  {t("create_new_application")}
                </p>
              </HashLink>
              <HashLink
                smooth
                style={{ textDecoration: "none" }}
                to="#admission_procedure"
              >
                <p
                  className={
                    darkMode && url === "admission_procedure"
                      ? style.subIndexDarkActive
                      : !darkMode && url === "admission_procedure"
                      ? style.subIndexLightActive
                      : darkMode && url !== "admission_procedure"
                      ? style.subIndexDark
                      : style.subIndexLight
                  }
                  onClick={() => setUrl("admission_procedure")}
                >
                  {t("procedure_of_admission")}
                </p>
              </HashLink>
              <HashLink
                smooth
                style={{ textDecoration: "none" }}
                to="#cancel_admission"
              >
                <p
                  className={
                    darkMode && url === "cancel_admission"
                      ? style.subIndexDarkActive
                      : !darkMode && url === "cancel_admission"
                      ? style.subIndexLightActive
                      : darkMode && url !== "cancel_admission"
                      ? style.subIndexDark
                      : style.subIndexLight
                  }
                  onClick={() => setUrl("cancel_admission")}
                >
                  {t("hoe_to_cancel_admission")}
                </p>
              </HashLink>

              <HashLink
                smooth
                style={{ textDecoration: "none" }}
                to="#complete_admission"
              >
                <p
                  className={
                    darkMode && url === "complete_admission"
                      ? style.subIndexDarkActive
                      : !darkMode && url === "complete_admission"
                      ? style.subIndexLightActive
                      : darkMode && url !== "complete_admission"
                      ? style.subIndexDark
                      : style.subIndexLight
                  }
                  onClick={() => setUrl("complete_admission")}
                >
                  {t("complete_admission")}
                </p>
              </HashLink>

              <HashLink
                smooth
                style={{ textDecoration: "none" }}
                to="#pending_fees"
              >
                <p
                  className={
                    darkMode && url === "pending_fees"
                      ? style.subIndexDarkActive
                      : !darkMode && url === "pending_fees"
                      ? style.subIndexLightActive
                      : darkMode && url !== "pending_fees"
                      ? style.subIndexDark
                      : style.subIndexLight
                  }
                  onClick={() => setUrl("pending_fees")}
                >
                  {t("check_pending_fees_collect")}
                </p>
              </HashLink>

              <HashLink
                smooth
                style={{ textDecoration: "none" }}
                to="#enquire_admission"
              >
                <p
                  className={
                    darkMode && url === "enquire_admission"
                      ? style.subIndexDarkActive
                      : !darkMode && url === "enquire_admission"
                      ? style.subIndexLightActive
                      : darkMode && url !== "enquire_admission"
                      ? style.subIndexDark
                      : style.subIndexLight
                  }
                  onClick={() => setUrl("enquire_admission")}
                >
                  {t("enquire_about_admission")}
                </p>
              </HashLink>

              <HashLink
                smooth
                style={{ textDecoration: "none" }}
                to="#accept_fee_receipt_request"
              >
                <p
                  className={
                    darkMode && url === "accept_fee_receipt_request"
                      ? style.subIndexDarkActive
                      : !darkMode && url === "accept_fee_receipt_request"
                      ? style.subIndexLightActive
                      : darkMode && url !== "accept_fee_receipt_request"
                      ? style.subIndexDark
                      : style.subIndexLight
                  }
                  onClick={() => setUrl("accept_fee_receipt_request")}
                >
                  {t("accept_fee_receipt_request")}
                </p>
              </HashLink>

              <HashLink
                smooth
                style={{ textDecoration: "none" }}
                to="#required_documents"
              >
                <p
                  className={
                    darkMode && url === "required_documents"
                      ? style.subIndexDarkActive
                      : !darkMode && url === "required_documents"
                      ? style.subIndexLightActive
                      : darkMode && url !== "required_documents"
                      ? style.subIndexDark
                      : style.subIndexLight
                  }
                  onClick={() => setUrl("required_documents")}
                >
                  {t("required_documents")}
                </p>
              </HashLink>

              <HashLink
                smooth
                style={{ textDecoration: "none" }}
                to="#refund_amount"
              >
                <p
                  className={
                    darkMode && url === "refund_amount"
                      ? style.subIndexDarkActive
                      : !darkMode && url === "refund_amount"
                      ? style.subIndexLightActive
                      : darkMode && url !== "refund_amount"
                      ? style.subIndexDark
                      : style.subIndexLight
                  }
                  onClick={() => setUrl("refund_amount")}
                >
                  {t("refund_heading")}
                </p>
              </HashLink>

              <HashLink
                smooth
                style={{ textDecoration: "none" }}
                to="#admission_data_export"
              >
                <p
                  className={
                    darkMode && url === "admission_data_export"
                      ? style.subIndexDarkActive
                      : !darkMode && url === "admission_data_export"
                      ? style.subIndexLightActive
                      : darkMode && url !== "admission_data_export"
                      ? style.subIndexDark
                      : style.subIndexLight
                  }
                  onClick={() => setUrl("admission_data_export")}
                >
                  {t("admission_data_export")}
                </p>
              </HashLink>

              <HashLink
                smooth
                style={{ textDecoration: "none" }}
                to="#admissionModerator"
              >
                <p
                  className={
                    darkMode && url === "admissionModerator"
                      ? style.subIndexDarkActive
                      : !darkMode && url === "admissionModerator"
                      ? style.subIndexLightActive
                      : darkMode && url !== "admissionModerator"
                      ? style.subIndexDark
                      : style.subIndexLight
                  }
                  onClick={() => setUrl("admissionModerator")}
                >
                  {t("admission_moderator")}
                </p>
              </HashLink>

              <HashLink
                smooth
                style={{ textDecoration: "none" }}
                to="#scholarship"
              >
                <p
                  className={
                    darkMode && url === "scholarship"
                      ? style.subIndexDarkActive
                      : !darkMode && url === "scholarship"
                      ? style.subIndexLightActive
                      : darkMode && url !== "scholarship"
                      ? style.subIndexDark
                      : style.subIndexLight
                  }
                  onClick={() => setUrl("scholarship")}
                >
                  {t("scholarship_heading")}
                </p>
              </HashLink>

              <HashLink
                smooth
                style={{ textDecoration: "none" }}
                to="#admission_fund"
              >
                <p
                  className={
                    darkMode && url === "admission_fund"
                      ? style.subIndexDarkActive
                      : !darkMode && url === "admission_fund"
                      ? style.subIndexLightActive
                      : darkMode && url !== "admission_fund"
                      ? style.subIndexDark
                      : style.subIndexLight
                  }
                  onClick={() => setUrl("admission_fund")}
                >
                  {t("submit_fund_to_finance_manager")}
                </p>
              </HashLink>
            </div>
          )}

          <TabChangeLink status="user" module="librarian">
            <div
              className={
                darkMode && openstatus === 12
                  ? `${style.sections} ${style.sectionsDarkActive}`
                  : !darkMode && openstatus === 12
                  ? `${style.sections} ${style.sectionsActive}`
                  : darkMode && !openstatus === 12
                  ? `${style.sections} ${style.sectionsDarkInactive}`
                  : style.sections
              }
              onClick={() => {
                setOpenStatus(openstatus === 12 ? 0 : 12);
              }}
            >
              {darkMode ? (
                <img alt="" src="/images/book-icon-white.svg" />
              ) : (
                <img alt="" src="/images/book-icon.svg" />
              )}
              <h6
                className={
                  darkMode ? style.primeindexDark : style.primeindexLight
                }
              >
                {t("librarian")}
              </h6>
              {openstatus === 12 ? (
                darkMode ? (
                  <img alt="" src="/images/chevron-up-white.svg" />
                ) : (
                  <img alt="" src="/images/chevron-up.svg" />
                )
              ) : darkMode ? (
                <img alt="" src="/images/chevron-down-white.svg" />
              ) : (
                <img alt="" src="/images/chevron-down.svg" />
              )}
            </div>
          </TabChangeLink>

          {openstatus === 12 && (
            <div
              className={
                darkMode
                  ? style.subIndexContainerDark
                  : style.subIndexContainerLight
              }
            >
              <HashLink
                smooth
                style={{ textDecoration: "none" }}
                to="#add_book"
              >
                <p
                  className={
                    darkMode && url === "add_book"
                      ? style.subIndexDarkActive
                      : !darkMode && url === "add_book"
                      ? style.subIndexLightActive
                      : darkMode && url !== "add_book"
                      ? style.subIndexDark
                      : style.subIndexLight
                  }
                  onClick={() => setUrl("add_book")}
                >
                  {t("add_new_book")}
                </p>
              </HashLink>
              <HashLink
                smooth
                style={{ textDecoration: "none" }}
                to="#issue_book"
              >
                <p
                  className={
                    darkMode && url === "issue_book"
                      ? style.subIndexDarkActive
                      : !darkMode && url === "issue_book"
                      ? style.subIndexLightActive
                      : darkMode && url !== "issue_book"
                      ? style.subIndexDark
                      : style.subIndexLight
                  }
                  onClick={() => setUrl("issue_book")}
                >
                  {t("issue_book")}
                </p>
              </HashLink>

              <HashLink
                smooth
                style={{ textDecoration: "none" }}
                to="#collect_book"
              >
                <p
                  className={
                    darkMode && url === "collect_book"
                      ? style.subIndexDarkActive
                      : !darkMode && url === "collect_book"
                      ? style.subIndexLightActive
                      : darkMode && url !== "collect_book"
                      ? style.subIndexDark
                      : style.subIndexLight
                  }
                  onClick={() => setUrl("collect_book")}
                >
                  {t("collect_book")}
                </p>
              </HashLink>

              <HashLink
                smooth
                style={{ textDecoration: "none" }}
                to="#member_history"
              >
                <p
                  className={
                    darkMode && url === "member_history"
                      ? style.subIndexDarkActive
                      : !darkMode && url === "member_history"
                      ? style.subIndexLightActive
                      : darkMode && url !== "member_history"
                      ? style.subIndexDark
                      : style.subIndexLight
                  }
                  onClick={() => setUrl("member_history")}
                >
                  {t("member_history")}
                </p>
              </HashLink>
            </div>
          )}

          <TabChangeLink status="user" module="event-manager">
            <div
              className={
                darkMode && openstatus === 13
                  ? `${style.sections} ${style.sectionsDarkActive}`
                  : !darkMode && openstatus === 13
                  ? `${style.sections} ${style.sectionsActive}`
                  : darkMode && !openstatus === 13
                  ? `${style.sections} ${style.sectionsDarkInactive}`
                  : style.sections
              }
              onClick={() => {
                setOpenStatus(openstatus === 13 ? 0 : 13);
              }}
            >
              {darkMode ? (
                <img alt="" src="/images/event_icon_white.svg" />
              ) : (
                <img alt="" src="/images/event_icon.svg" />
              )}
              <h6
                className={
                  darkMode ? style.primeindexDark : style.primeindexLight
                }
              >
                {t("working_as_event_manager")}
              </h6>
              {openstatus === 13 ? (
                darkMode ? (
                  <img alt="" src="/images/chevron-up-white.svg" />
                ) : (
                  <img alt="" src="/images/chevron-up.svg" />
                )
              ) : darkMode ? (
                <img alt="" src="/images/chevron-down-white.svg" />
              ) : (
                <img alt="" src="/images/chevron-down.svg" />
              )}
            </div>
          </TabChangeLink>

          {openstatus === 13 && (
            <div
              className={
                darkMode
                  ? style.subIndexContainerDark
                  : style.subIndexContainerLight
              }
            >
              <HashLink smooth style={{ textDecoration: "none" }} to="#event_">
                <p
                  className={
                    darkMode && url === "event_"
                      ? style.subIndexDarkActive
                      : !darkMode && url === "event_"
                      ? style.subIndexLightActive
                      : darkMode && url !== "event_"
                      ? style.subIndexDark
                      : style.subIndexLight
                  }
                  onClick={() => setUrl("event_")}
                >
                  {t("create_event")}
                </p>
              </HashLink>
              <HashLink
                smooth
                style={{ textDecoration: "none" }}
                to="#seminar_"
              >
                <p
                  className={
                    darkMode && url === "seminar_"
                      ? style.subIndexDarkActive
                      : !darkMode && url === "seminar_"
                      ? style.subIndexLightActive
                      : darkMode && url !== "seminar_"
                      ? style.subIndexDark
                      : style.subIndexLight
                  }
                  onClick={() => setUrl("seminar_")}
                >
                  {t("seminars_heading")}
                </p>
              </HashLink>

              <HashLink
                smooth
                style={{ textDecoration: "none" }}
                to="#create_election"
              >
                <p
                  className={
                    darkMode && url === "create_election"
                      ? style.subIndexDarkActive
                      : !darkMode && url === "create_election"
                      ? style.subIndexLightActive
                      : darkMode && url !== "create_election"
                      ? style.subIndexDark
                      : style.subIndexLight
                  }
                  onClick={() => setUrl("create_election")}
                >
                  {t("create_election_heading")}
                </p>
              </HashLink>

              <HashLink
                smooth
                style={{ textDecoration: "none" }}
                to="#create_competition"
              >
                <p
                  className={
                    darkMode && url === "create_competition"
                      ? style.subIndexDarkActive
                      : !darkMode && url === "create_competition"
                      ? style.subIndexLightActive
                      : darkMode && url !== "create_competition"
                      ? style.subIndexDark
                      : style.subIndexLight
                  }
                  onClick={() => setUrl("create_competition")}
                >
                  {t("create_competition")}
                </p>
              </HashLink>
            </div>
          )}

          <TabChangeLink status="user" module="hostel-manager">
            <div
              className={
                darkMode && openstatus === 14
                  ? `${style.sections} ${style.sectionsDarkActive}`
                  : !darkMode && openstatus === 14
                  ? `${style.sections} ${style.sectionsActive}`
                  : darkMode && !openstatus === 14
                  ? `${style.sections} ${style.sectionsDarkInactive}`
                  : style.sections
              }
              onClick={() => {
                setOpenStatus(openstatus === 14 ? 0 : 14);
              }}
            >
              {darkMode ? (
                <img alt="" src="/images/home_white_icon.svg" />
              ) : (
                <img alt="" src="/images/home_icon.svg" />
              )}
              <h6
                className={
                  darkMode ? style.primeindexDark : style.primeindexLight
                }
              >
                {t("working_as_hostel_manager")}
              </h6>
              {openstatus === 14 ? (
                darkMode ? (
                  <img alt="" src="/images/chevron-up-white.svg" />
                ) : (
                  <img alt="" src="/images/chevron-up.svg" />
                )
              ) : darkMode ? (
                <img alt="" src="/images/chevron-down-white.svg" />
              ) : (
                <img alt="" src="/images/chevron-down.svg" />
              )}
            </div>
          </TabChangeLink>

          {openstatus === 14 && (
            <div
              className={
                darkMode
                  ? style.subIndexContainerDark
                  : style.subIndexContainerLight
              }
            >
              <HashLink
                smooth
                style={{ textDecoration: "none" }}
                to="#warden_list"
              >
                <p
                  className={
                    darkMode && url === "warden_list"
                      ? style.subIndexDarkActive
                      : !darkMode && url === "warden_list"
                      ? style.subIndexLightActive
                      : darkMode && url !== "warden_list"
                      ? style.subIndexDark
                      : style.subIndexLight
                  }
                  onClick={() => setUrl("warden_list")}
                >
                  {t("view_warden_list")}
                </p>
              </HashLink>
              <HashLink
                smooth
                style={{ textDecoration: "none" }}
                to="#create_hostel_units"
              >
                <p
                  className={
                    darkMode && url === "create_hostel_units"
                      ? style.subIndexDarkActive
                      : !darkMode && url === "create_hostel_units"
                      ? style.subIndexLightActive
                      : darkMode && url !== "create_hostel_units"
                      ? style.subIndexDark
                      : style.subIndexLight
                  }
                  onClick={() => setUrl("create_hostel_units")}
                >
                  {t("create_hostel_units")}
                </p>
              </HashLink>

              <HashLink
                smooth
                style={{ textDecoration: "none" }}
                to="#view_hostel_unit"
              >
                <p
                  className={
                    darkMode && url === "view_hostel_unit"
                      ? style.subIndexDarkActive
                      : !darkMode && url === "view_hostel_unit"
                      ? style.subIndexLightActive
                      : darkMode && url !== "view_hostel_unit"
                      ? style.subIndexDark
                      : style.subIndexLight
                  }
                  onClick={() => setUrl("view_hostel_unit")}
                >
                  {t("view_hostel_unit")}
                </p>
              </HashLink>

              <HashLink
                smooth
                style={{ textDecoration: "none" }}
                to="#view_hostel_room"
              >
                <p
                  className={
                    darkMode && url === "view_hostel_room"
                      ? style.subIndexDarkActive
                      : !darkMode && url === "view_hostel_room"
                      ? style.subIndexLightActive
                      : darkMode && url !== "view_hostel_room"
                      ? style.subIndexDark
                      : style.subIndexLight
                  }
                  onClick={() => setUrl("view_hostel_room")}
                >
                  {t("view_hostel_room")}
                </p>
              </HashLink>

              <HashLink
                smooth
                style={{ textDecoration: "none" }}
                to="#view_hostelites"
              >
                <p
                  className={
                    darkMode && url === "view_hostelites"
                      ? style.subIndexDarkActive
                      : !darkMode && url === "view_hostelites"
                      ? style.subIndexLightActive
                      : darkMode && url !== "view_hostelites"
                      ? style.subIndexDark
                      : style.subIndexLight
                  }
                  onClick={() => setUrl("view_hostelites")}
                >
                  {t("view_hostelites")}
                </p>
              </HashLink>
              <HashLink
                smooth
                style={{ textDecoration: "none" }}
                to="#create_new_hostel_application"
              >
                <p
                  className={
                    darkMode && url === "create_new_hostel_application"
                      ? style.subIndexDarkActive
                      : !darkMode && url === "create_new_hostel_application"
                      ? style.subIndexLightActive
                      : darkMode && url !== "create_new_hostel_application"
                      ? style.subIndexDark
                      : style.subIndexLight
                  }
                  onClick={() => setUrl("create_new_hostel_application")}
                >
                  {t("create_new_hostel_application")}
                </p>
              </HashLink>

              <HashLink
                smooth
                style={{ textDecoration: "none" }}
                to="#new_hostel_application_process"
              >
                <p
                  className={
                    darkMode && url === "new_hostel_application_process"
                      ? style.subIndexDarkActive
                      : !darkMode && url === "new_hostel_application_process"
                      ? style.subIndexLightActive
                      : darkMode && url !== "new_hostel_application_process"
                      ? style.subIndexDark
                      : style.subIndexLight
                  }
                  onClick={() => setUrl("new_hostel_application_process")}
                >
                  {t("new_hostel_application_process")}
                </p>
              </HashLink>

              <HashLink
                smooth
                style={{ textDecoration: "none" }}
                to="#complete_hostel_admission_process"
              >
                <p
                  className={
                    darkMode && url === "complete_hostel_admission_process"
                      ? style.subIndexDarkActive
                      : !darkMode && url === "complete_hostel_admission_process"
                      ? style.subIndexLightActive
                      : darkMode && url !== "complete_hostel_admission_process"
                      ? style.subIndexDark
                      : style.subIndexLight
                  }
                  onClick={() => setUrl("complete_hostel_admission_process")}
                >
                  {t("complete_hostel_admission_process")}
                </p>
              </HashLink>

              <HashLink
                smooth
                style={{ textDecoration: "none" }}
                to="#control_hostel_form_details"
              >
                <p
                  className={
                    darkMode && url === "control_hostel_form_details"
                      ? style.subIndexDarkActive
                      : !darkMode && url === "control_hostel_form_details"
                      ? style.subIndexLightActive
                      : darkMode && url !== "control_hostel_form_details"
                      ? style.subIndexDark
                      : style.subIndexLight
                  }
                  onClick={() => setUrl("control_hostel_form_details")}
                >
                  {t("control_hostel_form_details")}
                </p>
              </HashLink>
              <HashLink
                smooth
                style={{ textDecoration: "none" }}
                to="#collect_hostel_pending_fee"
              >
                <p
                  className={
                    darkMode && url === "collect_hostel_pending_fee"
                      ? style.subIndexDarkActive
                      : !darkMode && url === "collect_hostel_pending_fee"
                      ? style.subIndexLightActive
                      : darkMode && url !== "collect_hostel_pending_fee"
                      ? style.subIndexDark
                      : style.subIndexLight
                  }
                  onClick={() => setUrl("collect_hostel_pending_fee")}
                >
                  {t("collect_hostel_pending_fee")}
                </p>
              </HashLink>

              <HashLink
                smooth
                style={{ textDecoration: "none" }}
                to="#accept_hostel_fee_receipt_request"
              >
                <p
                  className={
                    darkMode && url === "accept_hostel_fee_receipt_request"
                      ? style.subIndexDarkActive
                      : !darkMode && url === "accept_hostel_fee_receipt_request"
                      ? style.subIndexLightActive
                      : darkMode && url !== "accept_hostel_fee_receipt_request"
                      ? style.subIndexDark
                      : style.subIndexLight
                  }
                  onClick={() => setUrl("accept_hostel_fee_receipt_request")}
                >
                  {t("accept_hostel_fee_receipt_request")}
                </p>
              </HashLink>

              <HashLink
                smooth
                style={{ textDecoration: "none" }}
                to="#create_announcement"
              >
                <p
                  className={
                    darkMode && url === "create_announcement"
                      ? style.subIndexDarkActive
                      : !darkMode && url === "create_announcement"
                      ? style.subIndexLightActive
                      : darkMode && url !== "create_announcement"
                      ? style.subIndexDark
                      : style.subIndexLight
                  }
                  onClick={() => setUrl("create_announcement")}
                >
                  {t("create_announcement")}
                </p>
              </HashLink>

              <HashLink
                smooth
                style={{ textDecoration: "none" }}
                to="#create_rule"
              >
                <p
                  className={
                    darkMode && url === "create_rule"
                      ? style.subIndexDarkActive
                      : !darkMode && url === "create_rule"
                      ? style.subIndexLightActive
                      : darkMode && url !== "create_rule"
                      ? style.subIndexDark
                      : style.subIndexLight
                  }
                  onClick={() => setUrl("create_rule")}
                >
                  {t("create_rule")}
                </p>
              </HashLink>
            </div>
          )}

          <TabChangeLink status="user" module="transport-manager">
            <div
              className={
                darkMode && openstatus === 15
                  ? `${style.sections} ${style.sectionsDarkActive}`
                  : !darkMode && openstatus === 15
                  ? `${style.sections} ${style.sectionsActive}`
                  : darkMode && !openstatus === 15
                  ? `${style.sections} ${style.sectionsDarkInactive}`
                  : style.sections
              }
              onClick={() => {
                setOpenStatus(openstatus === 15 ? 0 : 15);
              }}
            >
              {darkMode ? (
                <img alt="" src="/images/transport_white_icon.svg" />
              ) : (
                <img alt="" src="/images/transport_icon.svg" />
              )}
              <h6
                className={
                  darkMode ? style.primeindexDark : style.primeindexLight
                }
              >
                {t("transport_heading")}
              </h6>
              {openstatus === 15 ? (
                darkMode ? (
                  <img alt="" src="/images/chevron-up-white.svg" />
                ) : (
                  <img alt="" src="/images/chevron-up.svg" />
                )
              ) : darkMode ? (
                <img alt="" src="/images/chevron-down-white.svg" />
              ) : (
                <img alt="" src="/images/chevron-down.svg" />
              )}
            </div>
          </TabChangeLink>

          {openstatus === 15 && (
            <div
              className={
                darkMode
                  ? style.subIndexContainerDark
                  : style.subIndexContainerLight
              }
            >
              <HashLink
                smooth
                style={{ textDecoration: "none" }}
                to="#view_vehicle_list"
              >
                <p
                  className={
                    darkMode && url === "warden_list"
                      ? style.subIndexDarkActive
                      : !darkMode && url === "warden_list"
                      ? style.subIndexLightActive
                      : darkMode && url !== "warden_list"
                      ? style.subIndexDark
                      : style.subIndexLight
                  }
                  onClick={() => setUrl("warden_list")}
                >
                  {t("view_vehicle_list")}
                </p>
              </HashLink>
              <HashLink
                smooth
                style={{ textDecoration: "none" }}
                to="#add_passanger"
              >
                <p
                  className={
                    darkMode && url === "add_passanger"
                      ? style.subIndexDarkActive
                      : !darkMode && url === "add_passanger"
                      ? style.subIndexLightActive
                      : darkMode && url !== "add_passanger"
                      ? style.subIndexDark
                      : style.subIndexLight
                  }
                  onClick={() => setUrl("add_passanger")}
                >
                  {t("add_passanger")}
                </p>
              </HashLink>

              <HashLink
                smooth
                style={{ textDecoration: "none" }}
                to="#driver_list"
              >
                <p
                  className={
                    darkMode && url === "driver_list"
                      ? style.subIndexDarkActive
                      : !darkMode && url === "driver_list"
                      ? style.subIndexLightActive
                      : darkMode && url !== "driver_list"
                      ? style.subIndexDark
                      : style.subIndexLight
                  }
                  onClick={() => setUrl("driver_list")}
                >
                  {t("driver_list")}
                </p>
              </HashLink>

              <HashLink
                smooth
                style={{ textDecoration: "none" }}
                to="#view_passanger_list"
              >
                <p
                  className={
                    darkMode && url === "view_passanger_list"
                      ? style.subIndexDarkActive
                      : !darkMode && url === "view_passanger_list"
                      ? style.subIndexLightActive
                      : darkMode && url !== "view_passanger_list"
                      ? style.subIndexDark
                      : style.subIndexLight
                  }
                  onClick={() => setUrl("view_passanger_list")}
                >
                  {t("view_passanger_list")}
                </p>
              </HashLink>

              <HashLink
                smooth
                style={{ textDecoration: "none" }}
                to="#submit_transport_fund_to_finance_manager"
              >
                <p
                  className={
                    darkMode &&
                    url === "submit_transport_fund_to_finance_manager"
                      ? style.subIndexDarkActive
                      : !darkMode &&
                        url === "submit_transport_fund_to_finance_manager"
                      ? style.subIndexLightActive
                      : darkMode &&
                        url !== "submit_transport_fund_to_finance_manager"
                      ? style.subIndexDark
                      : style.subIndexLight
                  }
                  onClick={() =>
                    setUrl("submit_transport_fund_to_finance_manager")
                  }
                >
                  {t("submit_transport_fund_to_finance_manager")}
                </p>
              </HashLink>
            </div>
          )}

          {/* <div
            className={
              darkMode && openstatus === 10
                ? `${style.sections} ${style.sectionsDarkActive}`
                : !darkMode && openstatus === 10
                ? `${style.sections} ${style.sectionsActive}`
                : darkMode && !openstatus === 10
                ? `${style.sections} ${style.sectionsDarkInactive}`
                : style.sections
            }
            onClick={() => {
              setOpenStatus(10);
            }}
          >
            {darkMode ? (
              <img alt="" src="/images/sport_head_white.svg" />
            ) : (
              <img alt="" src="/images/sport_head.svg" />
            )}
            <h6
              className={
                darkMode ? style.primeindexDark : style.primeindexLight
              }
            >
              {t("as_sport_head_coach")}
            </h6>
            {openstatus === 10 ? (
              darkMode ? (
                <img alt="" src="/images/chevron-up-white.svg" />
              ) : (
                <img alt="" src="/images/chevron-up.svg" />
              )
            ) : darkMode ? (
              <img alt="" src="/images/chevron-down-white.svg" />
            ) : (
              <img alt="" src="/images/chevron-down.svg" />
            )}
          </div> */}

          {/* {openstatus === 10 && (
            <div
              className={
                darkMode
                  ? style.subIndexContainerDark
                  : style.subIndexContainerLight
              }
            >
              <HashLink
                smooth
                style={{ textDecoration: "none" }}
                to="#organise_event"
              >
                <p
                  className={
                    darkMode && url === "organise_event"
                      ? style.subIndexDarkActive
                      : !darkMode && url === "organise_event"
                      ? style.subIndexLightActive
                      : darkMode && url !== "organise_event"
                      ? style.subIndexDark
                      : style.subIndexLight
                  }
                  onClick={() => setUrl("organise_event")}
                >
                  {t("organise_create_event")}
                </p>
              </HashLink>
              <HashLink
                smooth
                style={{ textDecoration: "none" }}
                to="#create_matches"
              >
                <p
                  className={
                    darkMode && url === "create_matches"
                      ? style.subIndexDarkActive
                      : !darkMode && url === "create_matches"
                      ? style.subIndexLightActive
                      : darkMode && url !== "create_matches"
                      ? style.subIndexDark
                      : style.subIndexLight
                  }
                  onClick={() => setUrl("create_matches")}
                >
                  {t("create_matches")}
                </p>
              </HashLink>
              <HashLink
                smooth
                style={{ textDecoration: "none" }}
                to="#upload_match_result"
              >
                <p
                  className={
                    darkMode && url === "upload_match_result"
                      ? style.subIndexDarkActive
                      : !darkMode && url === "upload_match_result"
                      ? style.subIndexLightActive
                      : darkMode && url !== "upload_match_result"
                      ? style.subIndexDark
                      : style.subIndexLight
                  }
                  onClick={() => setUrl("upload_match_result")}
                >
                  {t("upload_match_result")}
                </p>
              </HashLink>
            </div>
          )}

          <div
            className={
              darkMode && openstatus === 11
                ? `${style.sections} ${style.sectionsDarkActive}`
                : !darkMode && openstatus === 11
                ? `${style.sections} ${style.sectionsActive}`
                : darkMode && !openstatus === 11
                ? `${style.sections} ${style.sectionsDarkInactive}`
                : style.sections
            }
            onClick={() => {
              setOpenStatus(11);
            }}
          >
            {darkMode ? (
              <img alt="" src="/images/sport_coach_white.svg" />
            ) : (
              <img alt="" src="/images/sport_coach.svg" />
            )}
            <h6
              className={
                darkMode ? style.primeindexDark : style.primeindexLight
              }
            >
              {t("sport_class_coach")}
            </h6>
            {openstatus === 11 ? (
              darkMode ? (
                <img alt="" src="/images/chevron-up-white.svg" />
              ) : (
                <img alt="" src="/images/chevron-up.svg" />
              )
            ) : darkMode ? (
              <img alt="" src="/images/chevron-down-white.svg" />
            ) : (
              <img alt="" src="/images/chevron-down.svg" />
            )}
          </div>

          {openstatus === 11 && (
            <div
              className={
                darkMode
                  ? style.subIndexContainerDark
                  : style.subIndexContainerLight
              }
            >
              <HashLink smooth style={{ textDecoration: "none" }} to="#add_members">
                <p
                  className={
                    darkMode && url === "add_members"
                      ? style.subIndexDarkActive
                      : !darkMode && url === "add_members"
                      ? style.subIndexLightActive
                      : darkMode && url !== "add_members"
                      ? style.subIndexDark
                      : style.subIndexLight
                  }
                  onClick={() => setUrl("add_members")}
                >
                  {t("add_members")}
                </p>
              </HashLink>
              <HashLink smooth style={{ textDecoration: "none" }} to="#create_team">
                <p
                  className={
                    darkMode && url === "create_team"
                      ? style.subIndexDarkActive
                      : !darkMode && url === "create_team"
                      ? style.subIndexLightActive
                      : darkMode && url !== "create_team"
                      ? style.subIndexDark
                      : style.subIndexLight
                  }
                  onClick={() => setUrl("create_team")}
                >
                  {t("create_team")}
                </p>
              </HashLink>
            </div>
          )} */}
        </div>
      )}
    </div>
  );
}

export default Sidebar;
