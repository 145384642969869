import React from "react";
import { Link } from "react-router-dom";

function TabChangeLink({ children, status, module }) {
  return (
    <Link to={`/${window.location.pathname.split("/")[1]}/${status}/${module}`}>
      {children}
    </Link>
  );
}

export default TabChangeLink;
