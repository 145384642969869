import React, { Suspense, lazy } from "react";
import style from "./Content.module.css";
import { Route, Routes } from "react-router-dom";
import UserContent from "./UserContent/UserContent";

const GettingStarted = lazy(() => import("./GettingStarted/GettingStarted"));
const GetToKnowDashboard = lazy(() =>
  import("./GetToKnowDashboard/GetToKnowDashboard")
);
const InsInitialSetup = lazy(() => import("./InsInitialSetup/InsInitialSetup"));
const InsStaffActivity = lazy(() =>
  import("./InsStaffActivity/InsStaffActivity")
);
const InsStudentActivity = lazy(() =>
  import("./InsStudentActivity/InsStudentActivity")
);

function Content({ status, darkMode, tabIndex, setTabIndex }) {
  return (
    <div className={style.content}>
      {status === "institute" && (
        <Suspense fallback={<div>Loading...</div>}>
          <Routes>
            <Route
              path={`/:langUrl/institute`}
              exact
              element={<GettingStarted darkMode={darkMode} />}
            />

            <Route
              path={`/:langUrl/institute/dashboard`}
              exact
              element={<GetToKnowDashboard darkMode={darkMode} />}
            />
            <Route
              path={`/:langUrl/institute/setup`}
              exact
              element={<InsInitialSetup darkMode={darkMode} />}
            />
            <Route
              path={`/:langUrl/institute/staff-activity`}
              exact
              element={<InsStaffActivity darkMode={darkMode} />}
            />
            <Route
              path={`/:langUrl/institute/student-activity`}
              exact
              element={<InsStudentActivity darkMode={darkMode} />}
            />
          </Routes>
        </Suspense>
      )}

      {status === "user" && (
        <UserContent
          darkMode={darkMode}
          tabIndex={tabIndex}
          setTabIndex={setTabIndex}
        />
      )}
    </div>
  );
}

export default Content;
