import React, { useState, useEffect } from "react";
import style from "./Navbar.module.css";
import { useTranslation } from "react-i18next";
import i18next from "i18next";
import { Options } from "./Options/Options";
import { Link } from "react-router-dom";
import LangChangeLink from "../../Custom/LangChangeLink";

function NavRight({
  darkMode,
  setDarkMode,
  resposive,
  setOpenDrawer,
  openDrawer,
  location,
}) {
  const { i18n } = useTranslation();
  const localDark = localStorage.getItem("dark");
  const languages = [
    { code: "en", name: "English" },
    { code: "hn", name: "हिंदी" },
    { code: "mt", name: "मराठी" },
  ];
  const [lan, setLan] = useState("");
  const [lang, setLang] = useState(languages[0].name);
  const [click, setClick] = useState(false);

  const handleChange = (option) => {
    setLan(option.code);
    setLang(option.name);
  };

  useEffect(() => {
    if (lan === "en") {
      i18next.changeLanguage(languages[0].code);
    } else if (lan === "hn") {
      i18next.changeLanguage(languages[1].code);
    } else if (lan === "mt") {
      i18next.changeLanguage(languages[2].code);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [lan]);

  // const changeDark = () => {
  //   if (darkMode) {
  //     localStorage.setItem("dark", false);
  //   } else {
  //     localStorage.setItem("dark", true);
  //   }
  // };

  return (
    <>
      {!resposive && (
        <div className={style.navright}>
          {darkMode ? (
            <img
              className={style.themeImg}
              alt=""
              src="/images/moon_icon.png"
              onClick={() => {
                setDarkMode(!darkMode);
                localStorage.setItem("dark", !darkMode);
                // changeDark();
              }}
            />
          ) : (
            <img
              className={style.themeImg}
              alt=""
              src="/images/light-theme-icon.svg"
              onClick={() => {
                setDarkMode(!darkMode);
                localStorage.setItem("dark", !darkMode);
                // changeDark();
              }}
            />
          )}
          <div className={style.languages}>
            <LangChangeLink
              lang={languages[0].code}
              status={location.pathname.split("/")[2]}
              active={location.pathname.split("/")[3]}
              hash={location.hash}
            >
              <p
                className={
                  darkMode && i18n.language === "en"
                    ? style.darkActive
                    : !darkMode && i18n.language === "en"
                    ? style.active
                    : darkMode && i18n.language !== "en"
                    ? style.darkpnormal
                    : ""
                }
                onClick={() => {
                  i18next.changeLanguage(languages[0].code);
                  localStorage.setItem("lang", "en");
                }}
              >
                {languages[0].name}
              </p>
            </LangChangeLink>

            <LangChangeLink
              lang={languages[1].code}
              status={location.pathname.split("/")[2]}
              active={location.pathname.split("/")[3]}
              hash={location.hash}
            >
              <p
                className={
                  darkMode && i18n.language === "hn"
                    ? style.darkActive
                    : !darkMode && i18n.language === "hn"
                    ? style.active
                    : darkMode && i18n.language !== "hn"
                    ? style.darkpnormal
                    : ""
                }
                onClick={() => {
                  i18next.changeLanguage(languages[1].code);
                  localStorage.setItem("lang", "hn");
                }}
              >
                {languages[1].name}
              </p>
            </LangChangeLink>
            <LangChangeLink
              lang={languages[2].code}
              status={location.pathname.split("/")[2]}
              active={location.pathname.split("/")[3]}
              hash={location.hash}
            >
              <p
                className={
                  darkMode && i18n.language === "mt"
                    ? style.darkActive
                    : !darkMode && i18n.language === "mt"
                    ? style.active
                    : darkMode && i18n.language !== "mt"
                    ? style.darkpnormal
                    : ""
                }
                onClick={() => {
                  i18next.changeLanguage(languages[2].code);
                  localStorage.setItem("lang", "mt");
                }}
              >
                {languages[2].name}
              </p>
            </LangChangeLink>
          </div>

          {darkMode ? (
            <a href="https://qviple.com/login">
              <div className={style.logindiv}>
                <p>Login</p>
                <img src="/images/login_white_icon.svg" alt="login" />
              </div>
            </a>
          ) : (
            <a href="https://qviple.com/login">
              <img
                className={style.loginBox}
                src="/images/logout.svg"
                alt="login"
              />
            </a>
          )}
        </div>
      )}

      {resposive && (
        <div className={style.navright}>
          {darkMode ? (
            <img
              className={resposive ? `${style.theme}` : ""}
              alt=""
              src="/images/moon_icon.png"
              onClick={() => {
                setDarkMode(!darkMode);
                localStorage.setItem("dark", !darkMode);
              }}
            />
          ) : (
            <img
              alt=""
              className={resposive ? `${style.theme}` : ""}
              src="/images/light-theme-icon.svg"
              onClick={() => {
                setDarkMode(!darkMode);
                localStorage.setItem("dark", !darkMode);
              }}
            />
          )}

          {/* <div className={style.languages}>
            <p
              onClick={() => {
                i18next.changeLanguage(languages[0].code);
              }}
            >
              {languages[0].name}
            </p>
            <p
              onClick={() => {
                i18next.changeLanguage(languages[1].code);
              }}
            >
              {" "}
              {languages[1].name}
            </p>
            <p
              onClick={() => {
                i18next.changeLanguage(languages[2].code);
              }}
            >
              {languages[2].name}
            </p>
          </div> */}

          {/* <div
            className={
              click ? `${style.reslang} ${style.reslangActive}` : style.reslang
            }
            onClick={() => setClick(!click)}
          >
            <p className={darkMode ? style.reslangpDark : style.reslangp}>
              {" "}
              {lang}{" "}
            </p>
            <img
              className={style.selectIcon}
              src="/images/dropdown-icon.svg"
              alt=""
            />
          </div> */}

          <img
            className={style.theme}
            onClick={() => setClick(!click)}
            src="/images/lang-icon.png"
            alt=""
          />

          {click && (
            <Options
              options={languages}
              switchOption={handleChange}
              open={click}
              onClose={() => setClick(false)}
              darkMode={darkMode}
              status={location.pathname.split("/")[2]}
              active={location.pathname.split("/")[3]}
              hash={location.hash}
            />
          )}

          {openDrawer ? (
            <img
              alt=""
              src="/images/Remove.svg"
              className={style.removeImg}
              onClick={() => setOpenDrawer(!openDrawer)}
            />
          ) : (
            <img
              alt=""
              src={
                darkMode ? "/images/menu_white.svg" : "/images/hamburger.svg"
              }
              className={darkMode ? style.darkmenu : ""}
              onClick={() => setOpenDrawer(!openDrawer)}
            />
          )}
        </div>
      )}
    </>
  );
}

export default NavRight;
