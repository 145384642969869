import React from "react";
import style from "./Navbar.module.css";
import NavLeft from "./NavLeft";
import NavMid from "./NavMid";
import NavRight from "./NavRight";

function Navbar({
  darkMode,
  setDarkMode,
  resposive,
  setOpenDrawer,
  openDrawer,
  location,
}) {
  return (
    <div
      className={darkMode ? `${style.navbar} ${style.darkbg}` : style.navbar}
    >
      <NavLeft darkMode={darkMode} setDarkMode={setDarkMode} />
      {!resposive && (
        <NavMid
          darkMode={darkMode}
          setDarkMode={setDarkMode}
          resposive={resposive}
        />
      )}
      <NavRight
        darkMode={darkMode}
        setDarkMode={setDarkMode}
        resposive={resposive}
        setOpenDrawer={setOpenDrawer}
        openDrawer={openDrawer}
        location={location}
      />
    </div>
  );
}

export default Navbar;
