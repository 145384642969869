import React, { Suspense, useEffect } from "react";
import "./App.css";
import "bootstrap/dist/css/bootstrap.min.css";
import QLoader from "./Loader/QLoader";
import AllRoutes from "./Routes/AllRoutes";
import { BrowserRouter as Router } from "react-router-dom";

function App() {
  return (
    <Suspense fallback={<QLoader />}>
      <Router>
        <AllRoutes />
      </Router>
    </Suspense>
  );
}

export default App;
