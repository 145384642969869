import React from "react";
import style from "./Options.module.css";
import { useTranslation } from "react-i18next";
import LangChangeLink from "../../../Custom/LangChangeLink";

const OVERLAY_STYLES = {
  position: "fixed",
  top: 0,
  left: 0,
  right: 0,
  bottom: 0,
  zIndex: 10,
};

const MODAL_STYLES = {
  position: "absolute",
  right: "30px",
  top: "65px",
  zIndex: 10,
};

export function Options({
  options,
  switchOption,
  open,
  onClose,
  darkMode,
  status,
  active,
  hash,
}) {
  const { t, i18n } = useTranslation();
  console.log(hash);
  return (
    <>
      <div onClick={onClose} className={style.overlay} style={OVERLAY_STYLES} />

      <div
        className={
          darkMode
            ? `${style.profileToolTip} ${style.modalDark}`
            : style.profileToolTip
        }
        //  style={MODAL_STYLES}
      >
        {/* <div class
        
        Name={style.profilelist}>
          {options.map((option, index) => (
            <div
              value={option.code}
              onClick={() => {
                switchOption(option);
                onClose();
              }}
              key={index}
            >
              {option.name}
            </div>
          ))}
        </div> */}

        <div
          className={
            darkMode
              ? `${style.profileToolTiphead} ${style.pHeadDark}`
              : style.profileToolTiphead
          }
        >
          {t("trans_to")}
        </div>

        <div className={style.profileToolTipContent}>
          <LangChangeLink
            lang={options[0].code}
            status={status}
            active={active}
            hash={hash}
          >
            <div
              onClick={() => {
                switchOption(options[0]);
                localStorage.setItem("lang", "en");
                onClose();
              }}
              className={
                i18n.language === "en"
                  ? style.profileToolTipitemactive
                  : style.profileToolTipitem
              }
            >
              <h6>{options[0].name}</h6>
            </div>
          </LangChangeLink>
          <LangChangeLink
            lang={options[1].code}
            status={status}
            active={active}
            hash={hash}
          >
            <div
              onClick={() => {
                switchOption(options[1]);
                localStorage.setItem("lang", "hn");
                onClose();
              }}
              className={
                i18n.language === "hn"
                  ? style.profileToolTipitemactive
                  : style.profileToolTipitem
              }
            >
              <h6>{options[1].name}</h6>
            </div>
          </LangChangeLink>
          <LangChangeLink
            lang={options[2].code}
            status={status}
            active={active}
            hash={hash}
          >
            <div
              onClick={() => {
                switchOption(options[2]);
                localStorage.setItem("lang", "mt");
                onClose();
              }}
              className={
                i18n.language === "mt"
                  ? style.profileToolTipitemactive
                  : style.profileToolTipitem
              }
            >
              <h6>{options[2].name}</h6>
            </div>
          </LangChangeLink>
        </div>
      </div>
    </>
  );
}

// export function Options2({ options, switchOption }) {

//   return (
//     <div className={style.profileToolTip}>
//       <div className={style.triangle}></div>

//       <div className={style.profilelist}>
//         {options.map((option, index) => (
//           <div value={option} onClick={() => switchOption(option)} key={index}>
//             {option}
//           </div>
//         ))}
//       </div>
//     </div>
//   );
// }
