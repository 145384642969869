import React, { useEffect } from "react";
import UserMaual from "../pages/UserMaual";
import { useParams } from "react-router-dom";
import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import LanguageDetector from "i18next-browser-languagedetector";
import HttpApi from "i18next-http-backend";
import { useLocation, useNavigate } from "react-router-dom";

function AllRoutes() {
  // const { langUrl, desigUrl } = useParams();

  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    if (location.pathname.split("/")[1] && location.pathname.split("/")[2]) {
      localStorage.setItem("lang", location.pathname.split("/")[1]);
      localStorage.setItem("desigurl", location.pathname.split("/")[2]);
    }
  }, [location]);
  const localLang = localStorage.getItem("lang");
  i18n
    .use(initReactI18next) // passes i18n down to react-i18next
    .use(LanguageDetector)
    .use(HttpApi)
    .init({
      supportedLngs: ["en", "hn", "mt"],
      lng: localLang ? localLang : "en",
      fallbackLng: localLang ? localLang : "en",
      detection: {
        order: ["cookie", "htmlTag", "localStorage", "path", "subdomain"],
        caches: ["cookie"],
      },
      backend: {
        loadPath: "/assets/localization/{{lng}}/translation.json",
      },
      react: {
        useSuspense: false,
      },
    });

  useEffect(() => {
    if (!location.pathname.split("/")[1] || !location.pathname.split("/")[2]) {
      navigate(`/en/institute`);
    }
  }, [location]);

  return (
    <UserMaual
      // desigUrl={desigUrl}
      location={location}
    />
  );
}

export default AllRoutes;
