import React, { useEffect, useState, useRef } from "react";
import Content from "./Content/Content";
import Drawer from "./Drawer/Drawer";
import style from "./MainBody.module.css";
import Sidebar from "./Sidebar/Sidebar";
import $ from "jquery";

function MainBody({ darkMode, resposive, openDrawer, setOpenDrawer }) {
  const localDesigUrl = localStorage.getItem("desigurl");
  const [status, setStatus] = useState(localDesigUrl ? localDesigUrl : "user");
  const [tabIndex, setTabIndex] = useState(0);
  const [openstatus, setOpenStatus] = useState(0);

  window.onload = function () {
    var urlHash = window.location.href.split("#")[1];
    var el = document.getElementById(`${urlHash}`);
    if (el) {
      el.scrollIntoView(true);
    }
  };

  // -------------Today------------

  // setTimeout(function () {
  //   if (navigator.userAgent.match(/(iPod|iPhone|iPad|Android)/)) {
  //     window.scrollTo(window.location.hash);
  //   } else {
  //     $("html,body").animate(
  //       {
  //         scrollTop: $(window.location.hash).offset().top,
  //       },
  //       500
  //     );
  //   }
  // }, 100);

  // -------------Today------------

  return (
    <div className={darkMode ? style.mainbodyDark : style.mainbody}>
      <div className={style.container}>
        {!resposive && (
          <div className={style.containerleft}>
            <Sidebar
              status={status}
              setStatus={setStatus}
              openstatus={openstatus}
              setOpenStatus={setOpenStatus}
              darkMode={darkMode}
              setTabIndex={setTabIndex}
            />
          </div>
        )}

        <div className={style.containerright}>
          <Content
            status={status}
            darkMode={darkMode}
            tabIndex={tabIndex}
            setTabIndex={setTabIndex}
          />
        </div>

        {openDrawer && (
          <Drawer
            status={status}
            setStatus={setStatus}
            openstatus={openstatus}
            setOpenStatus={setOpenStatus}
            darkMode={darkMode}
            open={openDrawer}
            onClose={() => setOpenDrawer(false)}
            setOpenDrawer={setOpenDrawer}
            setTabIndex={setTabIndex}
          />
        )}
      </div>
    </div>
  );
}

export default MainBody;
